import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'

export function useProspectApi() {
  const { fetcher, backendUrl } = useCustomFetcher()

  const getOrigins = async () => {
    const url = `${backendUrl}/user_origins`

    return fetcher.get(url).then(response => {
      return response.user_origins
    })
  }

  const subscribeNewsletter = async email => {
    const url = `${backendUrl}prospects/newsletter`
    const config = {
      prospect: {
        tags: 'Newsletter',
        is_person: true,
        email,
      },
    }
    return fetcher.post(url, config).then(response => response.success)
  }

  const subscribeProspect = async (prospect, config = {}) => {
    const url = `${backendUrl}prospects`

    return fetcher.post(url, prospect, config).then(response => response.success)
  }

  const boostMySalesProspect = async (prospect, biCampaign, emailToken, config = {}) => {
    const url = `${backendUrl}prospects/boost-my-sales`

    return fetcher
      .post(url, { prospect: prospect, bi_campaign: biCampaign, email_token: emailToken }, config)
      .then(response => response.success)
  }

  return {
    getOrigins,
    subscribeNewsletter,
    subscribeProspect,
    boostMySalesProspect,
  }
}
