import { useDefaultMetaData } from '@/composables/MetaData/useDefaultMetaData'
import { useCurrentLocale } from '@/composables/i18n/useCurrentLocale'

export function useHomeMetaData({ customKey } = {}) {
  const config = useRuntimeConfig()

  const { defaultSeo, defaultHead } = useDefaultMetaData({ customKey })
  const { country } = useCurrentLocale()

  const homeHead = () => {
    customKey = 'home'
    const d = defaultHead()
    return {
      head: {
        ...d,
      },
      meta: {
        ...defaultSeo(),
      },
      schema: [corporationSchema()],
    }
  }

  const corporationSchema = () => {
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Corporation',
          name: 'StockPro',
          url: `${config.public.PRODUCTION_URL}`,
          logo: `${config.public.PRODUCTION_URL}logo.svg`,
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: country.phoneNumber,
            contactType: 'customer service',
          },
          sameAs: [
            'https://www.facebook.com/stockprofrance/',
            'https://linkedin.com/company/stockpro',
            'https://www.youtube.com/channel/UCTvHKa_JcH4Oyn41h7qB7Tg',
            'https://www.stock-pro.fr',
            'https://twitter.com/francestockpro',
            `http://www.${country.host}`,
          ],
        },
      ],
    }
  }
  return {
    homeHead,
  }
}
