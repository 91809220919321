<template>
  <div ref="componentLazy" class="component-lazy" :style="`min-height: ${height}px;`">
    <transition name="fade">
      <slot :is-visible="isVisible" />
    </transition>
  </div>
</template>
<script setup>
import { useIntersectionStore } from '@/stores/intersection'

defineProps({
  height: {
    type: Number,
    default: 100,
  },
})

const isVisible = ref(false)
const componentLazy = ref(null)
const intersectionStore = useIntersectionStore()

onMounted(() => {
  // Init observer if not already done
  intersectionStore.initObserver()
  intersectionStore.observe(componentLazy.value, entry => {
    isVisible.value = entry.isIntersecting
    if (entry.isIntersecting) {
      intersectionStore.unobserve(componentLazy.value)
    }
  })
})

onUnmounted(() => {
  intersectionStore.unobserve(componentLazy.value)
})
</script>
<style lang="scss" scoped>
// .component-lazy {
//   min-height: --lazy-height;
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
