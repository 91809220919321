export function useClubMapper() {
  const currentClubMapping = JSON.stringify({
    mapping: {
      company_group: [
        'uid',
        'name',
        'kind',
        'key',
        'description',
        {
          company_group_configuration: [
            'components',
            'logo_url',
            'theme',
            'payment_policy',
            'delivery_policy',
            'banner',
          ],
        },
        'current_user_roles',
      ],
    },
  })

  const clubsMapping = JSON.stringify({
    mapping: {
      company_group: ['uid', 'name', 'company_group_configuration'],
    },
  })
  return {
    currentClubMapping,
    clubsMapping,
  }
}
