<template>
  <li v-if="totalCompanies > 1" class="company-selector-menu" data-test-id="company-selector-menu">
    <a class="pointer" @click="$emit('update-show-company-list', true)">
      <img
        src="@/assets/img/svg/icons/ico-building.svg"
        class="header-ico-scale"
        width="19"
        :alt="$t('header.companyChoice')"
        :title="$t('header.companyChoice')"
      />
      <span>{{ $t('header.companyChoice') }}</span>
    </a>
    <nav v-if="showCompanyList" class="submenu is-active company-selector" data-test-id="company-selector">
      <ul>
        <li class="searchbar u-d-f u-ai-c" data-test-id="company-selector-search">
          <form class="search-form" data-test-id="company-selector-search-form">
            <button class="search-btn" data-test-id="company-selector-search-btn" />
            <div class="search-input-wrapper">
              <input v-model="search" :placeholder="$t('fields.searchShort')" type="text" class="search-input" />
            </div>
          </form>
          <div class="icon-close u-bg-night-blue pointer" @click="$emit('update-show-company-list', false)" />
        </li>
        <div v-if="isLoading" class="loader company-selector-loader" />
        <template v-else>
          <li
            v-for="company in companies"
            :key="company.uid"
            :data-test-id="`company-${company.name.toLowerCase().replace(/\s/g, '-')}`"
          >
            <a class="pointer" @click="setCurrentCompany(company.uid)">
              <div
                class="company-selector-badge"
                :data-test-id="`company-badge-${company.name.toLowerCase().replace(/\s/g, '-')}`"
                :class="{ active: isCurrentCompany(company.uid) }"
              >
                {{ company.name[0] }}
              </div>
              <span
                class="company-selector-name"
                :data-test-id="`company-selector-${company.name.toLowerCase().replace(/\s/g, '-')}`"
              >
                {{ company.name }}
              </span>
              <img
                v-if="isCurrentCompany(company.uid)"
                src="@/assets/img/svg/icons/check/ico-check-grey.svg"
                alt="ico-check"
                width="30"
              />
            </a>
          </li>
          <li v-if="search && companies.length === 0" class="company-selector-clear">
            {{ $t('message.noResult') }}
          </li>
        </template>
        <Pagination
          v-if="!isLoading"
          :current-page="currentPage"
          :max-page="maxPage"
          :return-event="true"
          @update-page="updatePage"
        />
      </ul>
    </nav>
  </li>
</template>

<script setup>
import Pagination from '@/components/Pagination/Pagination'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

defineProps({
  showCompanyList: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update-show-company-list', 'close'])
const search = ref('')
const companies = ref([])
const currentPage = ref(1)
const perPage = ref(10)
const maxPage = ref(null)
const totalCompanies = ref(null)

watch(
  () => currentPage.value,
  () => {
    getCompanies()
  }
)
watch(
  () => search.value,
  () => {
    //delay for typing in input
    setTimeout(() => {
      getCompanies()
    }, 1000)
  }
)
onMounted(() => {
  getCompanies(true)
})

const close = () => {
  emit('close')
}

const isCurrentCompany = companyUid => {
  if (!userStore || !userStore.companyUid) return false
  return userStore.companyUid === companyUid
}
const { request } = useApiRequest()
// init params to have totalcompanies available for this user and not updating during search
const getCompanies = (init = false) => {
  const filter = {
    kind: 'PROFESSIONAL',
    page: currentPage.value,
    items_per_page: perPage.value,
    search: search.value,
  }
  if (userStore && userStore.isAdmin) {
    filter.full = true
  }
  request({
    req: useCompanyApi().getCompanies(filter),
  }).then(data => {
    companies.value = data?.companies
    maxPage.value = data?.page_count
    currentPage.value = data?.page
    if (init) {
      totalCompanies.value = data?.total
    }
  })
}
const setCurrentCompany = companyUid => {
  const localePath = useLocalePath()
  const user = {
    current_company_uid: companyUid,
  }
  request({
    req: useUserApi().update(userStore.uid, userStore.companyUid, user),
  })
    // .then(async () => {
    .then(async updatedUser => {
      userStore.setUser(updatedUser)
      const route = useRoute()
      const router = useRouter()
      if (route.path !== localePath('index')) {
        await router.push(localePath('index'))
      }
    })
    .finally(() => {
      close()
    })
}
const updatePage = newPage => {
  currentPage.value = newPage
}
</script>

<style scoped lang="scss">
.submenu.company-selector {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .company-selector-loader {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
  }
  .company-selector-clear {
    padding: 10px 20px;
    color: var(--steel-grey);
    font-weight: 500;
  }
  .company-selector-badge {
    padding: 2px 8px;
    background-color: var(--steel-grey);
    color: var(--white);
    margin-right: 15px;
    border-radius: 3px;
    font-weight: 800;
    &.active {
      background-color: var(--primary);
    }
  }
  .company-selector-name {
    flex-grow: 1;
  }
}
.searchbar {
  margin-left: 10px !important;
  .search-form {
    background-color: var(--bg-grey);
    border-radius: 8px;
    width: calc(100% - 10px);
  }
}
.submenu-toggle .submenu ul li a img :not(.company-selector-menu) {
  margin: 0;
}
</style>
