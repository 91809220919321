import sha256 from 'crypto-js/sha256'
import { useCurrentLocale } from '@/composables/i18n/useCurrentLocale'
import { useUserStore } from '@/stores/user'

export function useGTMAnalytics() {
  const sendTagEvent = (name, params) => {
    // init dataLayer if not exist
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ...{ event: 'tagPlanEvent', eventName: name }, ...params })
  }

  const sendEvent = (name, params) => {
    // init dataLayer if not exist
    window.dataLayer = window.dataLayer || []

    if (params?.ecommerce) {
      // Reset ecommerce payload before sending a new one
      window.dataLayer.push({ ecommerce: null })
    }
    if (name === 'login_status') {
      const userStore = useUserStore()
      params = {}
      if (userStore?.isAuthenticated && !!userStore?.user) {
        params = {
          login_status: 'login_in',
          user_id: userStore?.uid,
          user_email: sha256(userStore?.email).toString(),
          user_type: userStore?.roleName !== 'Individual' ? 'pro' : 'individual',
          user_status: 'none', // TODO, value missing from backend : buyer, seller, both
          user_subscription: 'none', // TODO, value missing from backend : CE, IM, PF
        }
      } else {
        params = { login_status: 'not_login_in' }
      }
    } else {
      params = {
        ...params,
      }
    }
    // console.log('sendEvent', { ...{ event: name }, ...params })
    window.dataLayer.push({ ...{ event: name }, ...params })
  }

  const sendScreen = (name, params = {}) => {
    const { tld, locale } = useCurrentLocale()
    // init dataLayer if not exist
    window.dataLayer = window.dataLayer || []
    const event = {
      ...params,
      event: 'page_view',
      page_template: name,
      page_market: tld,
      page_language: locale?.toUpperCase(),
    }
    // console.log('sendScreen', event)
    window.dataLayer.push(event)
  }
  return {
    sendTagEvent,
    sendEvent,
    sendScreen,
  }
}
