import qs from 'qs'

import { useClubStore } from '@/stores/club'
import { useI18nStore } from '@/stores/i18nStore'
import { useUserStore } from '@/stores/user'

export function useCustomFetcher() {
  const runtimeConfig = useRuntimeConfig()
  const backendUrl = runtimeConfig.public.BACKEND_URL
  const backendUrlV2 = backendUrl.replace('/api/v1', '/api/v2')
  const storyblokApi = useStoryblokApi()

  const fetchData = async ({ method, url, options = {}, body = null }) => {
    const controller = new AbortController()
    let queryParams = ''
    let mainError = null
    const { bearerToken } = storeToRefs(useUserStore())

    const headers = {
      ...options.headers,
    }
    if (bearerToken.value) {
      headers['Authorization'] = bearerToken.value
    }
    headers['Mkp-web'] = true
    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json'
      headers['Accept'] = 'application/json, text/plain, */*'
    }
    const i18nStore = useI18nStore()
    headers['X-Country'] = i18nStore.tld?.toLowerCase()
    headers['X-Language'] = i18nStore.locale
    const clubStore = useClubStore()
    if (clubStore.isClub) {
      const companyGroupHeader = clubStore.domain
      // console.log('set header for club ' + companyGroupHeader)
      headers['Company-Group'] = companyGroupHeader
      headers['X-Company-Group'] = companyGroupHeader
    }

    if (options.params) {
      const queryString = qs.stringify(options.params, { encode: false, arrayFormat: 'brackets' })
      queryParams = queryString ? `?${queryString}` : ''
      delete options.params
    }

    const fetchParams = {
      method,
      ...options,
      signal: controller.signal,
      body: body ? JSON.stringify(body) : null,
      headers,
      onRequestError({ error }) {
        // console.log('onRequestError', error)
        mainError = error
      },
      onResponse({ request, response }) {
        // console.log('onResponse', response)
        const total = response.headers.get('total')
        if (total) {
          response._data.total = total
        }
        if (request.includes('sign_in')) {
          const authorization = response.headers.get('authorization')
          response._data.authorization = authorization
        }
      },
      onResponseError({ response }) {
        // console.log('onResponseError', response)
        mainError = response._data
      },
    }
    if (method === 'delete' && !fetchParams.body) {
      // hack found here : https://github.com/unjs/h3/issues/375#issuecomment-1907976548
      fetchParams.body = []
    }

    if (import.meta.client) {
      await nextTick()
    }
    // console.log('url', url)

    try {
      let response = await useFetch(`${url}${queryParams}`, fetchParams)
      if (response.error?.value) {
        throw new Error(response.error.value || 'Error occurred')
      } else if (mainError) {
        throw new Error(mainError)
      }
      if (!response.data.value) {
        // The data was not cached, so fetch it from the server
        await response.refresh()
      } else {
        return response.data?.value || response.data || response
      }
    } catch (error) {
      if ((mainError || error)?.message === 'cart_as_status_in_progress') {
        // bypass this specific error to avoid potential 404 error when fired
        return Promise.resolve()
      }
      console.log('error useFetch trycatch', mainError, error)
      throw { response: { data: mainError || error } }
    }
  }

  const fetcher = {
    get: (url, options = {}) => {
      return fetchData({ method: 'get', url, options })
    },
    put: (url, body, options = {}) => {
      return fetchData({ method: 'put', url, options, body })
    },
    patch: (url, body, options = {}) => {
      return fetchData({ method: 'put', url, options, body })
    },
    post: (url, body, options = {}) => {
      return fetchData({ method: 'post', url, options, body })
    },
    delete: (url, options = {}, body = null) => {
      return fetchData({ method: 'delete', url, options, body })
    },
    customFetch: (params = {}) => {
      if (!params.url || !params.method) return Promise.reject(new Error('url and method are required'))
      const fetchParams = { method: params.method, url: params.url, options: params }
      if (params.data) {
        fetchParams.body = params.data
      }
      return fetchData(fetchParams)
    },
  }
  return {
    fetcher,
    backendUrl,
    backendUrlV2,
    storyblokApi,
  }
}
