<template>
  <div class="searchbar" data-test-id="search-bar">
    <form class="search-form" data-test-id="search-form" @submit.prevent="onSearch">
      <button type="submit" class="search-btn" title="search" />
      <div class="search-input-wrapper" data-test-id="search-input-wrapper">
        <input
          ref="searchbar"
          v-model="keywords"
          type="search"
          class="search-input"
          :placeholder="searchBarPlaceholder"
          @focus="onFocus"
          @blur="onBlur"
          @keyup="onChange"
        />
      </div>
    </form>
    <div v-if="isSuggestionsVisible || isOnlyOverlayVisible" class="search-autocomplete-wrapper">
      <div
        class="search-autocomplete-overlay"
        :class="{ 'club-banner-active': isClub }"
        :style="`--clubBannerHeight: ${clubBannerHeight}px`"
      />
      <div v-if="isLoading" class="loader search-autocomplete-content" />
      <div
        v-else
        class="search-autocomplete-content custom-scrollbar custom-scrollbar-blue"
        data-test-id="home-searchbar-results"
      >
        <div v-if="suggestions?.length > 0" data-test-id="search-suggestions-wrapper">
          <div class="search-suggestions-header" data-test-id="search-suggestions">
            {{ $t('search.suggestions') }}
          </div>
          <div
            v-for="(suggestion, i) in suggestions"
            v-show="suggestion?.name !== ''"
            :key="i"
            :data-test-id="`search-suggestion-${i}`"
            class="search-suggestion"
            @click="searchMode === 'STOCK' ? search(suggestion) : openReference(suggestion)"
          >
            <p v-html="emphasizeKeywords(suggestion.name || suggestion)" />
            <p v-if="suggestion.ref_co" class="search-suggestion-refco">{{ suggestion.ref_co }}</p>
          </div>
        </div>
        <template v-if="categoriesSlug?.length > 0">
          <div data-test-id="search-categories-wrapper">
            <p class="search-suggestions-header" data-test-id="search-categories">
              {{ $t('search.categories') }}
            </p>
            <div
              v-for="(category, i) in categoriesSlug"
              :key="category.slug"
              :data-test-id="`search-category-${i}`"
              class="search-suggestion"
              @click="searchCategory(category)"
            >
              <p v-html="emphasizeKeywords(category.name)" />
            </div>
          </div>
        </template>
        <div v-if="brandsSlug?.length > 0" data-test-id="search-brands-wrapper">
          <div class="search-suggestions-header" data-test-id="search-brands">
            {{ $t('search.brands') }}
          </div>
          <div
            v-for="(brand, i) in brandsSlug"
            :key="brand.slug"
            :data-test-id="`search-brand-${i}`"
            class="search-suggestion"
            @click="searchBrand(brand)"
          >
            <p v-html="emphasizeKeywords(brand.name)" />
          </div>
        </div>
        <div v-if="merchantSlug?.length > 0" data-test-id="search-merchants-wrapper">
          <div class="search-suggestions-header" data-test-id="search-merchants">
            {{ $t('search.merchants') }}
          </div>
          <div
            v-for="(merchant, i) in merchantSlug"
            :key="merchant.slug"
            :data-test-id="`search-merchant-${i}`"
            class="search-suggestion"
            @click="openMerchant(merchant)"
          >
            <p v-html="emphasizeKeywords(merchant.name)" />
          </div>
        </div>
        <div v-if="articlesTitle?.length > 0 && !isClub" data-test-id="search-articles-wrapper">
          <div class="search-suggestions-header" data-test-id="search-articles">
            {{ $t('blog.sectionTitle') }}
          </div>
          <div
            v-for="(article, i) in articlesTitle"
            :key="article.slug"
            :data-test-id="`search-article-${i}`"
            class="search-suggestion"
            @click="openArticle(article)"
          >
            <p v-html="emphasizeKeywords(article.content.title)" />
          </div>
        </div>
        <div v-if="!mkpSuggestions?.length && searchMode === 'STOCK'">
          <div class="search-suggestions-header mkp-suggestions" @click="autocompleteReferences">
            <div class="icon-search u-bg-primary" />
            {{ $t('search.mkpSuggestionsSearch') }}
          </div>
        </div>
        <div v-if="mkpSuggestions?.length > 0 && searchMode === 'STOCK'">
          <div class="search-suggestions-header" data-test-id="search-mkp-suggestions">
            {{ $t('search.mkpSuggestions') }}
          </div>
          <div
            v-for="(suggestion, i) in mkpSuggestions"
            :key="i"
            :data-test-id="`search-mkp-suggestion-${suggestion.name?.toLowerCase()?.replace(/\s/g, '-')}`"
            class="search-suggestion"
            @click="openReference(suggestion)"
          >
            <p v-html="emphasizeKeywords(suggestion.name) || suggestion" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Fuse from 'fuse.js'
import { useDeviceSize } from '@/composables/useDeviceSize'
import { useUserStore } from '@/stores/user'
import { useEmitterStore } from '@/stores/emitter'
import { useDynamicContentStore } from '@/stores/dynamicContent'

const timeout = ref(null)
const keywords = ref(null)
const suggestions = ref([])
const mkpSuggestions = ref([])
const categoriesSlug = ref([])
const brandsSlug = ref([])
const merchantSlug = ref([])
const suggestionSelected = ref(-1)
const isFocus = ref(false)
const clubBannerHeight = ref(0)
const { isMd } = useDeviceSize()
const { isClub } = useClub()

const isSuggestionsVisible = computed(() => {
  return isFocus.value && keywords.value !== undefined && keywords.value !== null && keywords.value.length >= 3
})

const isOnlyOverlayVisible = computed(() => {
  return isFocus.value && isMd.value
})

// STOCK or REFERENCE
const searchMode = computed(() => {
  if (route.name?.includes('stock')) {
    return 'STOCK'
  }
  return 'REFERENCE'
})

const { $i18n } = useNuxtApp()

const searchbar = ref(null)

const searchBarPlaceholder = computed(() => {
  if (!isMd.value) {
    if (isClub.value) {
      return $i18n.t('fields.searchClub')
    } else {
      return searchMode.value === 'STOCK' ? $i18n.t('fields.searchStock') : $i18n.t('fields.search')
    }
  }
  return $i18n.t('fields.searchName')
})

const blogArticles = computed(() => {
  const dynamicContentStore = useDynamicContentStore()
  return dynamicContentStore.getBlogArticles
})

const articlesTitle = computed(() => {
  if (blogArticles.value?.length) {
    // thresold = match index (0 = perfect / 1 = match anything)
    const fuseOptions = { keys: ['content.title'], threshold: 0.3, ignoreLocation: true }
    if (keywords.value?.length) {
      const fuse = new Fuse(blogArticles.value, fuseOptions)
      const fuseSearch = fuse.search(keywords.value)
      return fuseSearch
        ?.map(article => {
          return { ...article?.item, name: article?.item?.name?.slice(0, 100) }
        })
        .slice(0, 5)
    }
  }
  return []
})

const route = useRoute()

watch(
  () => route.path,
  (to, from) => {
    if (to !== from && !['category-slug', 'search-keywords'].includes(to.name)) {
      reset()
    }
  }
)

onMounted(() => {
  clubBannerHeight.value = document.querySelector('.club-banner')?.getBoundingClientRect()?.height || 0
})

onBeforeUnmount(() => {
  clubBannerHeight.value = 0
  if (timeout.value !== null) {
    clearTimeout(timeout.value)
  }
})

const onFocus = () => {
  isFocus.value = true
  isMd.value ? document.body.classList.add('no-scroll') : ''
  const { analytics, events } = useAnalytics()
  analytics.sendTagEvent(events.TAG_PLAN.SEARCH.CLICK_SEARCH_BAR)
  // get article on focus to preload list
  const { getDynamicContent } = useDynamicContent()
  getDynamicContent({
    contents: ['BlogArticle'],
    getAll: true, // Get all content to be sure to have all articles
  })
}

const close = () => {
  isFocus.value = false
  document.body.classList.remove('no-scroll')
  mkpSuggestions.value = []
}

const onBlur = () => {
  const suggestionsDiv = document.querySelector('.search-autocomplete-content:hover')
  if (suggestionsDiv !== null && suggestionsDiv !== undefined) {
    if (!(suggestionsDiv.length !== 0)) {
      close()
    }
  } else close()
}

const onSearch = () => {
  if (suggestionSelected.value < 0) search(keywords.value)
}

const onChange = () => {
  const { events } = useAnalytics()
  isOnlyOverlayVisible.value || isSuggestionsVisible.value
    ? document.body.classList.add('no-scroll')
    : document.body.classList.remove('no-scroll')
  const emitter = useEmitterStore()
  emitter.emit(events.CLOSE_BURGER_MENU)
  if (keywords.value && keywords.value.length >= 3 && isFocus.value) {
    clearTimeout(timeout.value)
    // Debouncing autocompletion
    timeout.value = setTimeout(() => {
      // if (searchMode.value === 'REFERENCE') {
      //   autocomplete()
      // } else {
      //   }
      autocomplete()
    }, 400)
  }
}

const router = useRouter()

const search = (text, mkp = false) => {
  if (text) {
    const localePath = useLocalePath()
    if (searchMode.value === 'REFERENCE' || mkp) {
      // delete unrecognize char for search from suggestions
      let textQuery = text.name || text
      if (Array.isArray(textQuery) && textQuery.length) textQuery = textQuery.join('')
      router.push(
        localePath({
          name: 'search-keywords',
          params: { keywords: textQuery },
        })
      )
    } else {
      router.push(localePath({ name: 'stock', query: { ...route.query, query: text, page: 1 } }))
    }
    reset(text.name || text)
  }
}

const searchCategory = cat => {
  const localePath = useLocalePath()
  if (searchMode.value === 'REFERENCE') {
    router.push(localePath({ name: 'category-slug', params: { slug: cat.slug } }))
  } else {
    router.push(localePath({ name: 'stock', query: { ...route.query, categories: cat.uid, page: 1 } }))
  }
  reset(cat.name)
}

const searchBrand = brand => {
  const localePath = useLocalePath()
  if (searchMode.value === 'REFERENCE') {
    router.push(localePath({ name: 'brand-brand', params: { brand: brand.slug } }))
  } else {
    router.push(localePath({ name: 'stock', query: { ...route.query, brands: brand.uid, page: 1 } }))
  }
  reset(brand.name)
}

const openMerchant = merchant => {
  const localePath = useLocalePath()
  router.push(localePath({ name: 'merchant-slug', params: { slug: merchant.slug } }))
  reset(merchant.name)
}

const openReference = ref => {
  const localePath = useLocalePath()
  router.push(localePath({ name: 'product-slug', params: { slug: ref.slug } }))
  reset(ref.name)
}

const openArticle = article => {
  const localePath = useLocalePath()
  router.push(localePath({ name: 'blog-article', params: { article: article.slug } }))
  reset(article.name)
}

const reset = (keyword = null) => {
  keywords.value = keyword
  suggestionSelected.value = -1
  isFocus.value = false
  searchbar.value?.blur()
  close()
}

const { request } = useApiRequest()

const autocompleteReferences = () => {
  const filters = [
    {
      type: 'KEYWORDS',
      value: keywords.value.trim(),
    },
  ]
  request({
    req: useAutocompleteApi().getMarketplace(filters),
  }).then(results => {
    if (results !== null && results !== undefined) {
      mkpSuggestions.value = results?.references?.map(ref => {
        return { ...ref, name: ref.name.slice(0, 100) }
      })
    }
  })
}

const autocomplete = async () => {
  const userStore = useUserStore()
  const filters = [
    {
      type: 'KEYWORDS',
      value: keywords.value,
    },
  ]
  let req = useAutocompleteApi().getMarketplace(filters)
  if (searchMode.value === 'STOCK') {
    req = useAutocompleteApi().getStock(userStore.companyUid, filters)
  }
  const results = await request({ req })
  if (results !== null && results !== undefined) {
    if (searchMode.value === 'STOCK') {
      suggestions.value = results.stocks?.map(stock => stock.reference.name.slice(0, 100))
    } else {
      suggestions.value = results.references?.map(ref => {
        return { ...ref, name: ref.name.slice(0, 100) }
      })
    }
    categoriesSlug.value = [
      ...(results.categories?.map(category => {
        return { ...category, name: category.name.slice(0, 100) }
      }) || []),
    ]
    brandsSlug.value = [
      ...(results.brands?.map(brand => {
        return { ...brand, name: brand.name.slice(0, 100) }
      }) || []),
    ]
    merchantSlug.value = results.companies?.map(merchant => {
      return { ...merchant, name: merchant.name.slice(0, 100) }
    })
  }
}

const emphasizeKeywords = text => {
  if (text && text.name !== '' && keywords.value) {
    const re = new RegExp(`(?<=${keywords.value})|(?=${keywords.value})`, 'gi')
    return text
      ?.split(re)
      .map(x => (re.test(x) ? `<span class="u-font-black">${x}</span>` : x))
      .join('')
  } else {
    return null
  }
}
</script>

<style lang="scss">
.searchbar {
  position: relative;

  .search-form {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--bg-grey);
    border-radius: 5px;
    overflow: hidden;
    padding: 5px 0;
    height: 42px;

    .search-input-wrapper {
      position: relative;
      flex: 1 1 calc(100% - 40px);
      padding: 0 10px 0 0;

      .search-input {
        outline: none;
        background-color: transparent;
        border: none;
        color: var(--night-blue);
        font-family: Avenir;
        font-size: pxToRem(15px);
        font-weight: 800;
        width: 100%;
        min-width: 0;

        &::placeholder {
          color: var(--steel-grey);
          font-size: pxToRem(15px);
          font-weight: normal;
        }
      }
    }

    .search-btn {
      outline: none;
      flex: 0 0 40px;
      width: 40px;
      height: 36px;
      margin: 0;
      padding: 0;
      text-align: center;
      background-color: transparent;
      appearance: none;
      border: none;
      color: var(--primary);
      cursor: pointer;
      mask-image: url('@/assets/img/svg/icons/ico-search.svg');
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: var(--primary);
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .search-autocomplete-wrapper {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    width: 100%;
    background: var(--white);
    border-radius: 0 0 5px 5px;
    padding: 0;
    overflow: hidden;

    .search-autocomplete-overlay {
      position: fixed;
      // top: $header-height;
      top: 80px;
      height: calc(100vh - 80px);
      left: -$navbar-left-width;
      width: calc(100vw + $navbar-left-width);
      backdrop-filter: blur(2px);
      z-index: 1;

      @include mq($mq-md) {
        // top: $header-height-sm;
        top: 100px;
      }
      @include mq($mq-sm) {
        // top: $header-height-sm;
        top: 163px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--black);
        opacity: 0.4;
      }
      &.club-banner-active {
        top: calc(81px + var(--clubBannerHeight));
        @include mq($mq-sm) {
          top: calc(81px + var(--clubBannerHeight));
        }
      }
    }

    .search-autocomplete-content {
      position: relative;
      z-index: 2;
      background-color: var(--white);
      max-height: calc(100vh - #{$header-height} - 20px);
      overflow: auto;
      padding-top: 5px;

      @include mq($mq-sm) {
        max-height: calc(100vh - #{$header-height-sm} - 20px);
      }

      .search-suggestions-header {
        background-color: var(--background-grey);
        font-weight: 800;
        padding: 10px 10px 10px 37px;

        @include mq($mq-sm) {
          padding: 10px;
        }

        &.mkp-suggestions {
          align-items: center;
          cursor: pointer;
          color: var(--primary);
          display: flex;
          gap: $spacing-sm;
          text-decoration: underline;
        }
      }

      .search-suggestion {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background-color: var(--white);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        line-height: 120%;
        padding: 10px 10px 10px 37px;
        margin: auto 0;

        @include mq($mq-sm) {
          padding: 10px;
        }

        &:hover,
        &.selected {
          background: var(--background-blue);
        }

        &-refco {
          background-color: var(--bg-grey);
          border-radius: $spacing-xs;
          font-size: pxToRem(12px);
          color: var(--blue-dark);
          font-weight: 800;
          height: 20px;
          line-height: 20px;
          padding: 0 $spacing-xs;
        }
      }
    }
  }
}
</style>
