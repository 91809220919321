<template>
  <StandardModal
    v-if="showLocationSelectionModal"
    max-width="380px"
    name="locationSelectionModal"
    class="location-selection-modal"
    :fullscreen="false"
    position="br"
    @close="close()"
  >
    <template #title>
      <div>
        <span
          v-if="userLocationCountryName"
          class="location-selection-modal-title"
          v-html="
            locationInScopeCountries
              ? navigatorLocale === 'EN'
                ? $t('i18n.targetDeliveryInfoEn', { country: userLocationCountryName })
                : $t('i18n.targetDeliveryInfo', { country: userLocationCountryName }, { locale: navigatorLocale })
              : navigatorLocale === 'EN'
                ? $t('i18n.wrongLocationEn', { country: userLocationCountryName })
                : $t('i18n.wrongLocation', { country: userLocationCountryName }, { locale: navigatorLocale })
          "
        />
      </div>
    </template>
    <template #content>
      <div v-if="!locationInScopeCountries" class="location-selection-modal-content">
        <div class="location-selection-modal-dropdown">
          <SelectField
            :selected-value="selectedHost?.intlName || countriesList[0]?.intlName"
            class-variant="bg-grey radius-md pointer"
            @click="showMenu = !showMenu"
          >
            <template #icon>
              <div class="icon-check u-bg-dark-blue" />
            </template>
          </SelectField>
          <div v-if="showMenu" class="location-selection-modal-dropdown-options">
            <div
              v-for="c in countriesList"
              :key="c?.code"
              :value="c"
              class="location-selection-modal-dropdown-options-option"
              @click="selectAndClose(c)"
            >
              <div class="icon-check" :class="{ 'u-bg-dark-blue': selectedHost?.code === c?.code }" />
              <div class="u-d-f u-ai-c u-f-gap-sm">
                {{ c.intlName }}
              </div>
            </div>
          </div>
        </div>
        <MainButton
          tag="a"
          class-variant="btn-medium"
          :label="$t('i18n.continue')"
          @click="selectHost(selectedHost, hostUrl(selectedHost?.host))"
        />
      </div>
    </template>
    <template #options>
      <div v-if="locationInScopeCountries">
        <div class="location-selection-modal-options">
          <MainButton
            tag="a"
            class-variant="btn-bg-grey-blue btn-hover-dark-blue btn-medium"
            :label="`${countriesList[1]?.intlName}`"
            @click="selectHost(countriesList[1])"
          />
          <MainButton
            tag="a"
            :label="`${countriesList[0]?.intlName}`"
            :link="hostUrl(countriesList[0]?.host)"
            class-variant="btn-primary full-width btn-medium"
            @click="selectHost(countriesList[0])"
          />
        </div>
      </div>
    </template>
  </StandardModal>
</template>

<script setup>
import { useEmitterStore } from '@/stores/emitter'
import * as Sentry from '@sentry/vue'

import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import SelectField from '@/components/SelectField/SelectField'

const { countries, getIsoNameCountries } = usei18nMx()
const { country, tld } = useCurrentLocale()

const { $i18n } = useNuxtApp()
const emitter = useEmitterStore()

const showLocationSelectionModal = ref(false)
const userLocation = ref(null)
const selectedHost = ref(null)
const showMenu = ref(false)

const countriesList = computed(() => {
  // determines buttons list
  if (locationInScopeCountries.value && userLocation.value) {
    return [
      countries.value.find(country => country.code.toUpperCase() === userLocation.value),
      countries.value.find(country => country.code.toUpperCase() === tld),
    ]
  }
  return countries.value
})
const locationInScopeCountries = computed(() => {
  if (userLocation.value) {
    return countries.value.map(x => x.code).includes(userLocation.value?.toLowerCase())
  }
  return true
})
const userLocationCountryName = computed(() => {
  if (userLocation.value) {
    return getIsoNameCountries(userLocation.value)
  }
  return getIsoNameCountries(tld)
})
const navigatorLocale = computed(() => {
  let locale = null
  const { locale: currentLocale } = useCurrentLocale()
  if (import.meta.client) {
    if (navigator?.language?.split('-')?.length) {
      locale = navigator?.language?.split('-')?.shift()?.toUpperCase() || currentLocale
    } else {
      locale = navigator?.language?.toUpperCase() || currentLocale
    }
  }
  return $i18n.localeCodes.value.includes(locale.toLowerCase()) ? locale : 'EN'
})
const navigatorLocation = computed(() => {
  if (import.meta.client) {
    if (navigator?.language?.split('-')?.length) {
      return navigator?.language?.split('-')?.pop()?.toUpperCase() || tld || 'FR'
    } else {
      return navigator?.language?.toUpperCase() || tld || 'FR'
    }
  }
  // default return TLD
  return tld
})

onMounted(() => {
  initGeoIp()
  selectedHost.value = country
})

const initGeoIp = () => {
  const countryIpCookie = useCookie('x-country-ip', {
    maxAge: 60 * 60 * 24 * 365,
    domain: `.${country?.host}`,
  })
  const locationModalDisabledCookie = useCookie('x-location_modal_disabled')
  // avoid multiple calls for same user by using cookies
  if (!countryIpCookie.value && !locationModalDisabledCookie.value) {
    // Init maxmind script
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.id = 'maxmind-geoip'
    script.onload = setTimeout(() => getGeoIp(), 500)
    script.src = 'https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js'
    const first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)
    // To avoid CORS issue in local env, use extension like https://chromewebstore.google.com/detail/moesif-origincors-changer/digfbfaphojjndkpccljibejjbppifbc?hl=fr
  } else {
    openModal()
  }
}

const getGeoIp = async () => {
  console.log('getGeoIp')
  let countryCookie = null
  if (window.geoip2) {
    try {
      await window.geoip2.country(
        data => {
          // console.log('data', data)
          if (data?.country) {
            countryCookie = data.country.iso_code
            const countryIpCookie = useCookie('x-country-ip', {
              maxAge: 60 * 60 * 24 * 365,
              domain: `.${country?.host}`,
            })
            countryIpCookie.value = data.country.iso_code
            openModal(countryCookie)
          }
        },
        error => {
          console.log(error)
          throw error
        }
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  } else {
    openModal(countryCookie)
  }
}

const openModal = countryCookie => {
  const countryIpCookie = useCookie('x-country-ip', {
    maxAge: 60 * 60 * 24 * 365,
    domain: `.${country?.host}`,
  })
  const locationModalDisabledCookie = useCookie('x-location_modal_disabled')
  const selectedHostCookie = useCookie('x-selectedHost', {
    maxAge: 60 * 60 * 24 * 365,
    domain: `.${country.host}`,
  })
  nextTick(() => {
    const locationInRightHost =
      (countryIpCookie.value || countryCookie) === tld ||
      (!(countryIpCookie.value || countryCookie) && navigatorLocation.value === tld)
    userLocation.value = countryIpCookie.value || countryCookie || navigatorLocation.value
    if (!locationInRightHost && !selectedHostCookie.value && !locationModalDisabledCookie.value) {
      showLocationSelectionModal.value = true
      nextTick(() => {
        emitter.emit('modalOpen_locationSelectionModal', 'locationSelectionModal')
      })
    }
  })
}

const selectHost = (country, url) => {
  const selectedHostCookie = useCookie('x-selectedHost', { maxAge: 60 * 60 * 24 * 365, domain: `.${country.host}` })
  selectedHostCookie.value = country.host
  if (url && country?.code?.toUpperCase() !== tld) {
    url += `?source_host=${tld}`
    openLink(url, false, ``)
  }
  close()
}

const hostUrl = host => {
  return `${window.location.protocol}//www.${host}`
}

const close = () => {
  // if close modal, set selectedHost to current host for 2 week
  const selectedHostCookie = useCookie('x-selectedHost', {
    maxAge: 60 * 60 * 24 * 14,
    domain: `.${country.host}`,
  })
  selectedHostCookie.value = country.host
  emitter.emit('modalClose_locationSelectionModal', 'locationSelectionModal')
}

const openLink = (url, blank = true) => {
  const link = document.createElement('a')
  link.href = url
  if (blank) {
    link.setAttribute('target', '_blank')
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
const selectAndClose = c => {
  selectedHost.value = c
  showMenu.value = false
}
</script>

<style lang="scss">
.location-selection-modal {
  .modal-wrapper .modal-container .modal-header .modal-header-title {
    font-size: pxToRem(15px) !important;
    @include mq($mq-md) {
      font-size: pxToRem(12px) !important;
    }
  }
  &-title {
    font-weight: 400;
    & span {
      color: var(--primary);
    }
  }
  &-description {
    padding: $spacing-md;
    & span {
      color: var(--primary);
    }
  }
  &-selection {
    border-bottom: 1px solid var(--light-grey);
    padding: $spacing-md;
    cursor: pointer;
    a {
      align-items: center;
      color: var(--dark-blue);
      display: flex;
      gap: $spacing-sm;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-options {
    align-items: center;
    display: flex;
    gap: $spacing-md;
    justify-content: space-between;
    padding: $spacing-md;
    &-button,
    a {
      flex: 1;
      width: 50%;
    }
  }
  &-content {
    display: flex;
    gap: $spacing-md;
    padding: $spacing-md;
  }
  &-dropdown {
    flex-grow: 1;
    position: relative;
    .sp-select-icon-left {
      padding-left: 10px;
      padding-top: 5px;
      margin-right: 5px;
    }
    &-options {
      background-color: var(--bg-grey);
      border-radius: $spacing-xs;
      bottom: 0;
      position: absolute;
      width: 100%;
      &-option {
        cursor: pointer;
        display: flex;
        gap: $spacing-xs;
        padding: $spacing-xs $spacing-sm;
        font-size: pxToRem(12px);
        font-weight: 600;
      }
    }
  }
  .modal-container {
    padding: 0 !important;
  }
  .modal-body {
    margin: 0 !important;
    font-size: pxToRem(15px);
  }
  .modal-header {
    margin-bottom: 0 !important;
    padding: $spacing-md $spacing-md 0 $spacing-md;
  }
}
</style>
