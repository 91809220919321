import { defineStore } from 'pinia'

import { useCurrenti18n } from '@/composables/i18n/useCurrenti18n'

export const useI18nStore = defineStore('i18nStore', () => {
  const tld = ref(null)
  const locale = ref(null)
  const country = ref(null)

  const langCode = (min = false, revert = false) => {
    if (min && locale.value?.toLowerCase() === tld.value?.toLowerCase()) {
      return locale.value
    }
    if (revert) {
      return tld.value?.toLowerCase() + '-' + locale.value?.toLowerCase()
    }
    return locale.value?.toLowerCase() + '-' + tld.value?.toLowerCase()
  }

  const init = async () => {
    const currenti18n = useCurrenti18n()
    // console.log('init i18nStore')
    if (!tld.value) {
      const currentTld = currenti18n.currentLocation()
      // console.log('i18nStore tld', tld)
      tld.value = currentTld
    }

    if (!locale.value) {
      const currentLocale = currenti18n.currentLocale()
      // console.log('i18nStore locale', locale)
      locale.value = currentLocale
      // ctx.i18n.locale = locale
    }

    if (!country.value) {
      const currentCountry = currenti18n.currentCountry()
      // console.log('i18nStore country', country)
      country.value = currentCountry
    }
  }

  return {
    tld,
    locale,
    country,
    langCode,
    init,
  }
})
