import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'

export function useLocationApi() {
  const { fetcher, backendUrl } = useCustomFetcher()
  const findLocation = async location => {
    const url = `${backendUrl}location`
    const config = {
      params: {
        address: location,
      },
    }
    return fetcher.get(url, config).then(response => response.location)
  }
  return {
    findLocation,
  }
}
