<template>
  <footer :class="{ 'partner-footer': partnerPage }">
    <div class="footer-container" data-test-id="footer-container">
      <div v-if="partnerPage" class="footer-cols">
        <div class="footer-col footer-about-partnership" data-test-id="footer-about-partnership">
          <p class="title">{{ rawLanding?.footer_title }}</p>
          <p>{{ rawLanding?.footer_description }}</p>
        </div>
      </div>
      <div v-else class="footer-cols footer-cols-2-3">
        <div id="newsletter-form" class="footer-col footer-newsletter" data-test-id="footer-newsletter">
          <p class="title">{{ $t('footer.newsletterTitle') }}</p>
          <p>{{ $t('footer.newsletterTxt') }}</p>
          <form class="footer-newsletter-form" data-test-id="footer-newsletter-form" @submit.prevent="submitEmail">
            <input
              v-model="email"
              type="email"
              name="email"
              data-test-id="footer-newsletter-input"
              :placeholder="$t('footer.newsletterPlaceholder')"
              required
            />
            <button type="submit" title="submit-newsletter" data-test-id="footer-newsletter-submit" />
          </form>
          <p v-if="error" class="footer-newsletter-error" data-test-id="footer-newsletter-error">
            {{ error }}
          </p>
        </div>
        <div class="footer-col footer-about" data-test-id="footer-about">
          <div class="footer-flex-title" data-test-id="footer-title">
            <p class="title">{{ $t('footer.aboutTitle') }}</p>
            <SocialIcons />
          </div>
          <client-only>
            <div v-if="footerAbout" data-test-id="footer-about-text" v-html="footerAbout" />
          </client-only>
        </div>
      </div>
      <div class="footer-cols">
        <div v-show="tld === 'FR'" class="footer-col" data-test-id="footer-app">
          <p class="title">{{ $t('footer.appTitle') }}</p>
          <div class="app-buttons" data-test-id="footer-app-buttons">
            <a
              href="https://apps.apple.com/fr/app/stock-pro/id1450359795"
              :title="$t('footer.appAppleTitle')"
              target="_blank"
              data-test-id="footer-apple-store"
              @click="trackDownloadLink"
            >
              <img
                loading="lazy"
                src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/btn-apple-store.svg"
                :alt="$t('footer.appAppleTitle')"
                :title="$t('footer.appAppleTitle')"
                width="139"
                height="36"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.stockpro"
              :title="$t('footer.appGoogleTitle')"
              target="_blank"
              data-test-id="footer-google-store"
              @click="trackDownloadLink"
            >
              <img
                loading="lazy"
                src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/btn-google-store.svg"
                :alt="$t('footer.appGoogleTitle')"
                :title="$t('footer.appGoogleTitle')"
                width="139"
                height="36"
              />
            </a>
          </div>
        </div>
        <div v-if="!partnerPage && !proPage" class="footer-col" data-test-id="footer-mkp-menu">
          <p class="title" data-test-id="footer-mkp-menu-title">{{ $t('footer.mkpMenuTitle') }}</p>
          <nav>
            <ul>
              <li data-test-id="footer-mkp-menu-home">
                <NuxtLinkLocale :to="'index'" :title="$t('pages.home')">
                  {{ $t('pages.home') }}
                </NuxtLinkLocale>
              </li>
              <li data-test-id="footer-mkp-menu-order">
                <NuxtLinkLocale :to="'my-account-orders'" :title="$t('header.myOrders')">
                  {{ $t('header.myOrders') }}
                </NuxtLinkLocale>
              </li>
              <li v-if="!userStore?.isAuthenticated || userStore?.isPro" data-test-id="footer-mkp-menu-sales">
                <NuxtLinkLocale :to="'my-account-sales'" :title="$t('header.mySales')">
                  {{ $t('header.mySales') }}
                </NuxtLinkLocale>
              </li>
              <li data-test-id="footer-mkp-menu-brand">
                <NuxtLinkLocale :to="'brands'" :title="$t('pages.ourBrands')">
                  {{ $t('pages.ourBrands') }}
                </NuxtLinkLocale>
              </li>
              <li data-test-id="footer-mkp-menu-mission">
                <NuxtLinkLocale :to="'mission'" :title="$t('pages.ourMission')">
                  {{ $t('pages.ourMission') }}
                </NuxtLinkLocale>
              </li>
              <li data-test-id="footer-mkp-menu-blog">
                <NuxtLinkLocale :to="'blog'" :title="$t('pages.blog')">
                  {{ $t('pages.blog') }}
                </NuxtLinkLocale>
              </li>
            </ul>
          </nav>
        </div>
        <client-only>
          <div v-if="!partnerPage" class="footer-col" data-test-id="footer-pro-menu">
            <p class="title" data-test-id="footer-pro-menu-title">{{ $t('footer.proMenuTitle') }}</p>
            <nav>
              <ul>
                <li data-test-id="footer-pro-menu-services">
                  <a :href="getProUrl()" :title="$t('footer.services')">
                    {{ $t('footer.services') }}
                  </a>
                </li>
                <client-only v-if="tld === 'FR'">
                  <li data-test-id="footer-pro-menu-stock">
                    <a :href="getProUrl('services/stockpro')" :title="$t('proArea.footer.stock')">
                      <span>{{ $t('proArea.footer.stock') }}</span>
                    </a>
                  </li>
                  <li data-test-id="footer-pro-menu-engine">
                    <a :href="getProUrl('services/stockproengine')" :title="$t('proArea.footer.engine')">
                      <span>{{ $t('proArea.footer.engine') }}</span>
                    </a>
                  </li>
                  <li data-test-id="footer-pro-menu-plan">
                    <a :href="getProUrl('tarifs')" :title="$t('proArea.footer.plans')">
                      <span>{{ $t('proArea.footer.plans') }}</span>
                    </a>
                  </li>
                  <li data-test-id="footer-pro-menu-sme-companies">
                    <a :href="getProUrl('offers/pme')" :title="$t('proArea.footer.sme_companies')">
                      <span>{{ $t('proArea.footer.sme_companies') }}</span>
                    </a>
                  </li>
                  <li data-test-id="footer-pro-menu-artisans">
                    <a :href="getProUrl('offers/artisans')" :title="$t('proArea.footer.artisans')">
                      <span>{{ $t('proArea.footer.artisans') }}</span>
                    </a>
                  </li>
                  <li data-test-id="footer-pro-menu-manufacturer">
                    <a :href="getProUrl('offers/fabricants')" :title="$t('proArea.footer.manufacturers')">
                      <span>{{ $t('proArea.footer.manufacturers') }}</span>
                    </a>
                  </li>
                  <li data-test-id="footer-pro-menu-distributor">
                    <a :href="getProUrl('offers/distributeurs')" :title="$t('proArea.footer.distributor')">
                      <span>{{ $t('proArea.footer.distributor') }}</span>
                    </a>
                  </li>
                </client-only>
              </ul>
            </nav>
          </div>
        </client-only>
        <div v-if="!partnerPage" class="footer-col" data-test-id="footer-legals-menu">
          <p class="title" data-test-id="footer-legals-menu-title">{{ $t('footer.legalsMenuTitle') }}</p>
          <nav>
            <ul>
              <li data-test-id="footer-legals-menu-cgv">
                <NuxtLinkLocale :to="'cgv'" :title="$t('pages.cgv')">
                  {{ $t('pages.cgv') }}
                </NuxtLinkLocale>
              </li>
              <li data-test-id="footer-legals-menu-cgu">
                <NuxtLinkLocale :to="'cgu'" :title="$t('pages.cgu')">
                  {{ $t('pages.cgu') }}
                </NuxtLinkLocale>
              </li>
              <li data-test-id="footer-legals-menu-privacy">
                <NuxtLinkLocale :to="'privacy'" :title="$t('pages.privacy')">
                  {{ $t('pages.privacy') }}
                </NuxtLinkLocale>
              </li>
              <li data-test-id="footer-legals-menu-cookies">
                <a href="javascript:openAxeptioCookies()">
                  {{ $t('footer.manageCookies') }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div v-if="!partnerPage" class="footer-col" data-test-id="footer-more-menu">
          <p class="title" data-test-id="footer-more-menu-title">{{ $t('footer.moreMenuTitle') }}</p>
          <nav>
            <ul>
              <client-only>
                <li data-test-id="footer-more-menu-demo">
                  <a v-if="demoLink" :href="demoLink" :title="$t('actions.demo')">
                    {{ $t('actions.demo') }}
                  </a>
                </li>
              </client-only>
              <li data-test-id="footer-more-menu-contact-tel">
                <a
                  :href="'tel:' + country?.phoneNumber?.replace(/\s/g, '')"
                  :title="$t('footer.phoneTitle')"
                  target="_blank"
                >
                  {{ country?.phoneNumber }}
                </a>
              </li>
              <li data-test-id="footer-more-menu-contact-form">
                <client-only>
                  <a
                    v-if="contactFormLink"
                    :href="contactFormLink"
                    :title="$t('footer.contactFormTitle')"
                    target="_blank"
                  >
                    {{ $t('actions.contactUs') }}
                  </a>
                </client-only>
              </li>
              <li data-test-id="footer-more-menu-faq">
                <client-only>
                  <a v-if="faqLink" :href="faqLink" :title="$t('pages.faq')" target="_blank">
                    {{ $t('pages.faq') }}
                  </a>
                </client-only>
              </li>
              <li v-show="tld === 'FR'" data-test-id="footer-more-menu-press">
                <NuxtLinkLocale :to="'press'" :title="$t('pages.press')">
                  {{ $t('pages.press') }}
                </NuxtLinkLocale>
              </li>
              <li v-show="tld !== 'FR'" data-test-id="footer-more-menu-press-and-mediakit">
                <a
                  href="https://www.notion.so/stockpro/StockPro-Media-Kit-7c0c1f83eae74c64b7f2e12aa59fd7cc"
                  :title="$t('pages.pressAndMediaKit')"
                  target="_blank"
                  rel="nofollow"
                >
                  {{ $t('pages.pressAndMediaKit') }}
                </a>
              </li>
              <li v-show="tld === 'FR'" data-test-id="footer-more-menu-job">
                <a href="https://stockpro.welcomekit.co/" :title="$t('pages.job')" target="_blank" rel="nofollow">
                  {{ $t('pages.job') }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="footer-cols footer-cols-bottom" data-test-id="footer-bottom">
        <!-- v-if="displayTrustpilot" -->
        <div
          v-if="!isClub"
          class="footer-col span-2"
          data-test-id="footer-trustpilot"
          :class="{ 'footer-col-trustpilot': tld === 'FR' }"
        >
          <ClientOnly>
            <!-- TrustBox widget - Mini -->
            <TrustpilotWidget
              :class="{ 'footer-col-trustpilot-left': tld !== 'FR' }"
              :data-template-id="tld === 'FR' ? '53aa8807dec7e10d38f59f32' : '56278e9abfbbba0bdcd568bc'"
              :data-style-height="tld === 'FR' ? '150px' : '52px'"
              :data-style-width="tld === 'FR' ? 'auto' : '350px'"
              :data-style-alignment="tld === 'FR' ? 'center' : 'left'"
              data-theme="dark"
              :element-test="elementTest + '-widget'"
              :with-link="true"
            />
            <!-- End TrustBox widget -->
          </ClientOnly>
        </div>
        <div class="footer-col hide-md" data-test-id="footer-copyrights">
          <p class="copyrights" :class="{ 'u-d-f u-jc-fe': tld !== 'FR' && !isSm }">
            {{
              $t('footer.copyrights', {
                year: new Date().getFullYear(),
              })
            }}
          </p>
        </div>
        <div class="footer-col footer-cols-logo span-2" data-test-id="footer-logos">
          <ImageLazy
            v-show="tld === 'FR'"
            class="footer-cols-logo-main"
            data-test-id="footer-logo-onu"
            src="https://d1l9hv9873iyuz.cloudfront.net/logo-ONU@3x.webp"
            alt="Objectifs de développement durable"
            title="Objectifs de développement durable"
            height="20"
          />
          <div class="footer-cols-logo-grid">
            <ImageLazy
              v-show="tld === 'FR'"
              data-test-id="footer-logo-greentech"
              width="63"
              height="63"
              src="https://d1l9hv9873iyuz.cloudfront.net/logo-greentech-3x.webp"
              alt="Greentech"
              title="Greentech"
            />
            <ImageLazy
              data-test-id="footer-logo-solar-impulse"
              src="https://d1l9hv9873iyuz.cloudfront.net/logo-solar-impulse.webp"
              alt="Solar Impulse"
              title="Solar Impulse"
              width="63"
              height="63"
            />
          </div>
        </div>
        <div class="footer-col show-md" data-test-id="footer-copyrights-hide">
          <p class="copyrights" :class="{ 'u-d-f u-jc-fe': tld !== 'FR' && !isSm }">
            {{
              $t('footer.copyrights', {
                year: new Date().getFullYear(),
              })
            }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import SocialIcons from '@/components/SocialIcons/SocialIcons'
import { useLandingDownloadStore } from '@/stores/landingDownload'
import { useUserStore } from '@/stores/user'
import TrustpilotWidget from '@/components/TrustpilotWidget'

defineProps({
  partnerPage: { type: Boolean, default: false },
  proPage: { type: Boolean, default: false },
  elementTest: { type: String, default: 'footer' },
})
const { country, locale, tld } = useCurrentLocale()
const { isSm } = useDeviceSize()
const { isClub } = useClub()
const { trackDownloadLink } = useDownloadApp()
const footerAbout = ref(country?.footerAbout[locale])
const contactFormLink = ref(country?.contactFormLink[locale])
const demoLink = ref(country?.demoLink[locale])
const faqLink = ref(country?.faqLink[locale])
const userStore = useUserStore()
const email = ref(null)
const error = ref(null)

const landingDownloadStore = useLandingDownloadStore()
const rawLanding = computed(() => {
  return landingDownloadStore.landingDownload || landingDownloadStore.landingDynamic
})

const submitEmail = () => {
  if (email.value && email.value !== '') {
    const { $i18n } = useNuxtApp()
    const { request } = useApiRequest()
    request({
      req: useProspectApi().subscribeNewsletter(email.value),
      loader: false,
    }).then(() => {
      const { analytics, events } = useAnalytics()
      analytics.sendEvent(events.GENERATE_LEAD, { form_label: 'contact' })
      // Cheatcode, use error to display newsletter success
      error.value = $i18n.t('message.subscribeSuccess')
    })
  }
}

const getProUrl = (slug = '') => {
  if (import.meta.client) {
    if (window.location.host.includes('www')) {
      return `${window.location.protocol}//${window.location.host.replace('www', 'pro')}/${slug}`
    } else if (window.location.host?.split('.')[0] !== 'pro') {
      return `${window.location.protocol}//pro.${window.location.host}/${slug}`
    } else {
      return `${window.location.protocol}//${window.location.host}/${slug}`
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/partials/components/footer.scss';
</style>
