export function useCustomStoryblokApi() {
  const runtimeConfig = useRuntimeConfig()
  const storyApi = useStoryblokApi()

  const get = async (url, params) => {
    if (!params['version']) {
      params['version'] = runtimeConfig.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft'
    }
    try {
      if (!params['token']) {
        params['token'] = runtimeConfig.public.STORYBLOK_KEY
      }
      if (!params['sort_by'] && !params.uniqueStory) {
        params['sort_by'] =
          'content.order:asc;content.previous_published_at:desc;first_published_at:desc;published_at:desc;created_at:desc'
      } else {
        delete params.uniqueStory
      }
      const response = await storyApi.get(`cdn/stories${url}`, params)
      return Promise.resolve(response)
    } catch (e) {
      // if no result, normal storyblok get
      console.log(e)
    }
  }
  return {
    get,
  }
}
