import { useAnalytics } from '@/composables/Analytics/useAnalytics'

export function useDownloadApp() {
  let downloadAppLink = 'https://apps.apple.com/fr/app/stock-pro/id1450359795'
  if (navigator?.userAgent?.match(/Android/i)) {
    downloadAppLink = 'https://play.google.com/store/apps/details?id=com.stockpro'
  }

  const trackDownloadLink = () => {
    const { analytics, events } = useAnalytics()
    analytics.sendEvent(events.SUBMIT_APPLICATION)
  }
  return {
    downloadAppLink,
    trackDownloadLink,
  }
}
