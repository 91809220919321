// This inject the default behavior for requesting StockPro API
// By default it handles a isLoading var
// By defaults it feeds the error var when needed
import { useCurrentLocale } from '@/composables/i18n/useCurrentLocale'

export function useApiRequest() {
  const isLoading = ref(false)
  const error = ref(null)

  const request = async ({ req, loader = true, errorMessages = [] }) => {
    const config = useRuntimeConfig()
    try {
      if (loader) isLoading.value = true
      error.value = null
      const response = await req
      if (config.public.NODE_ENV !== 'production') {
        // console.log('useApiRequest response', response)
      }
      if (!response) {
        throw new Error('no response')
      }
      if (response && errorMessages.includes(response.message)) {
        throw { response: { data: { ...response, statusCode: 301 } } }
      }
      return Promise.resolve(response)
    } catch (e) {
      // small hack to avoid 404 error if request is canceled
      if (e.message?.includes('RequestCanceled')) {
        // console.log('resolve canceled request')
        return Promise.resolve()
      }
      if (e.message === 'maintenance_in_progress') {
        console.log('Maintenance in progress')
        return Promise.reject(e)
      }
      const { $i18n } = useNuxtApp()
      const { country } = useCurrentLocale()
      if (e?.response?.data?.message && !errorMessages.includes(e.response.data.message)) {
        error.value = $i18n.te(`backendError.${e.response.data.message}`)
          ? $i18n.t(`backendError.${e.response.data.message}`, {
              phone: country?.phoneNumber,
            })
          : $i18n.t(`backendError.unknown`, {
              phone: country?.phoneNumber,
            })
        throw new Error(e.response.data.message)
        // return Promise.reject(e.response.data.message);
      }
      error.value = $i18n.t(`backendError.unknown`, {
        phone: country?.phoneNumber,
      })
      if (config.public.NODE_ENV !== 'production') {
        console.log(error.value)
        console.error('useApiRequest error', e)
      }
      return Promise.reject(e)
    } finally {
      if (loader) isLoading.value = false
    }
  }
  return {
    isLoading,
    error,
    request,
  }
}
