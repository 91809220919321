export function useScrollTo() {
  const scrollTo = (element, offset = 100, delay = 50, text = null, target = '*') => {
    setTimeout(() => {
      let el
      if (text) {
        el = document.evaluate(`//${target}[text()[contains(., "${element}")]]`, document.body).iterateNext()
      } else {
        el = document.querySelector(element)
      }
      if (el) {
        const top = el.getBoundingClientRect().top + window.scrollY
        if (top > 0) {
          window.scrollTo({ left: 0, top: top - offset, behavior: 'smooth' })
        }
      }
    }, delay)
  }

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    }, 50)
  }

  return {
    scrollTo,
    scrollToTop,
  }
}
