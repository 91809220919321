<template>
  <div class="sp-input-field" :class="[classVariant]" :data-test-id="elementTest">
    <div v-if="fieldTitle" class="sp-input-title-container" :data-test-id="elementTest + '-title'">
      <div
        v-if="fieldFocus || disableTitleFieldFocus"
        class="sp-input-title-text-container"
        :data-test-id="elementTest + '-title-text'"
      >
        <p
          class="sp-input-title-text"
          :class="{ 'u-c-primary': greenTitleWhenCorrect && correctInputValue }"
          :data-test-id="elementTest + '-title-text-p'"
        >
          {{ fieldTitleStr }}
        </p>
      </div>
    </div>
    <div class="sp-input-field-div" :data-test-id="elementTest + '-input'">
      <div
        v-if="icon && icon.length !== 0"
        class="sp-input-icon"
        :data-test-id="elementTest + '-icon'"
        :style="icon && icon.length !== 0 ? `background-image: url('${icon}');` : ''"
      />
      <div v-if="$slots.icon" class="sp-input-icon" :data-test-id="elementTest + '-icon'">
        <slot name="icon" />
      </div>
      <template v-if="selectedInputType === 'date'">
        <DatePicker
          v-if="mounted"
          v-model.string="inputValue"
          :max-date="maxDate"
          :locale="$i18n.locale || 'fr'"
          mode="date"
          :is-required="required"
          @dayclick="
            (day, event) => {
              event.target.blur()
            }
          "
        >
          <template #default="{ togglePopover, inputValue: datepickerValue, inputEvents }">
            <input
              type="text"
              :placeholder="placeholderStr"
              :value="datepickerValue"
              class="sp-input"
              :class="{
                activeInput,
                error: hasError,
                'has-icon': icon && icon.length !== 0,
              }"
              :disabled="disabled"
              :required="required"
              @focus="focusDatePicker(togglePopover)"
              @blur="blur"
              v-on="inputEvents"
            />
          </template>
        </DatePicker>
      </template>
      <template v-else>
        <input
          :id="inputId"
          v-model="inputValue"
          class="sp-input"
          :class="{
            activeInput,
            error: hasError,
            'has-icon': (icon && icon.length !== 0) || $slots.icon,
          }"
          :placeholder="placeholderStr"
          :type="selectedInputType"
          :data-input-type="inputType"
          :maxlength="fieldLength"
          :min="min"
          :max="max"
          :disabled="disabled"
          :required="required"
          :pattern="pattern"
          :step="step"
          :clearable="clearable"
          @focus="focus"
          @blur="blur"
          @change="change"
          @input="input"
        />
        <button
          v-if="inputType === 'password'"
          class="sp-input-password-button"
          type="button"
          :data-test-id="elementTest + '-password-button'"
          :data-input-type="selectedInputType"
          @click="toggleShowPassword()"
        />
        <button
          v-if="clearable && !disabled && inputValue && inputValue.length"
          class="sp-input-clear-button"
          :data-test-id="elementTest + '-clear-button'"
          type="button"
          @click="clear()"
        />
      </template>
    </div>
    <div v-if="hasError && inputValue !== ''" class="error sp-input-error" :data-test-id="elementTest + '-error'">
      <p>
        {{ error }}
      </p>
    </div>
    <div v-else-if="v$.$errors?.length" class="error sp-input-error" :data-test-id="elementTest + '-rule-error'">
      <span v-for="rule in Object.keys(validations)" :key="rule">
        <div v-if="v$.inputValue[rule]">
          {{ $t(`rules.${rule}`, v$.inputValue[rule].min || v$.inputValue[rule].max) }}
        </div>
      </span>
    </div>
  </div>
</template>

<script setup>
import { DatePicker } from 'v-calendar'

const props = defineProps({
  // Used for live formatting input from parent
  modelValue: { type: [String, Number, Date], default: '' },
  placeholderValue: { type: String, default: '' },
  inputId: { type: String, default: null },
  fieldTitle: { type: String, default: '' },
  inputType: { type: String, default: '' },
  fieldLength: { type: String, default: '' },
  min: { type: String, default: null },
  max: { type: String, default: null },
  disabled: { type: Boolean, default: false },
  error: { type: String, default: '' },
  icon: { type: String, default: '' },
  pattern: { type: String, default: null },
  required: { type: Boolean, default: false },
  openDate: { type: Date, default: null },
  maxDate: { type: Date, default: () => null },
  step: { type: String, default: () => '' },
  clearable: { type: Boolean, default: false },
  classVariant: { type: null, default: null },
  inputFormatter: {
    type: Function,
    default(input) {
      return input
    },
  },
  inputRules: { type: Array, default: () => [] }, // ['required', ['minLength', 4], ['sameAs', 'password']]
  disableTitleFieldFocus: { type: Boolean, default: false },
  greenTitleWhenCorrect: { type: Boolean, default: false }, // Green title label when input value is ok
  elementTest: { type: String, default: '' },
})
const emit = defineEmits([
  'update:modelValue',
  'focus',
  'blur',
  'on-value',
  'change',
  'clear',
  'check',
  'update:model-value',
  'validate',
])

const inputValue = ref(null)
const activeInput = ref(false)
const fieldFocus = ref(false)
const selectedInputType = ref(props.inputType)
const mounted = ref(false)

const { v$, validations } = useRules(props.inputRules, 'inputValue', inputValue)

const hasError = computed(() => {
  return props.error.length !== 0
})
const correctInputValue = computed(() => {
  return props.modelValue && props.modelValue !== '' && !v$.value.inputValue.$error
})
const isRequired = computed(() => {
  return props.inputRules.includes('required')
})
const placeholderStr = computed(() => {
  return props.placeholderValue + (isRequired.value && !props.disableTitleFieldFocus ? ' *' : '')
})
const fieldTitleStr = computed(() => {
  return props.fieldTitle + (isRequired.value ? ' *' : '')
})

watch(
  () => props.modelValue,
  v => {
    updateValue(v)
    blur()
  }
)

watch(
  () => inputValue.value,
  v => {
    updateValue(v)
  }
)

onMounted(() => {
  blur()
  mounted.value = true
  inputValue.value = props.modelValue
})
onBeforeUnmount(() => {
  mounted.value = false
})
const showLabel = () => {
  fieldFocus.value = true
}
const updateValue = newValue => {
  inputValue.value = props.inputFormatter(newValue)
  v$.value.inputValue.$model = inputValue.value
  activeInput.value = (inputValue.value && inputValue.value.length > 0) || inputValue.value instanceof Date
  emit('on-value', inputValue.value)
  emit('update:modelValue', inputValue.value)
  v$.value.$touch()
  emit('check')
  emit('validate', !!v$.value.$errors?.length)
}
const toggleShowPassword = () => {
  if (selectedInputType.value === 'password') {
    selectedInputType.value = 'text'
  } else {
    selectedInputType.value = 'password'
  }
}
const clear = () => {
  inputValue.value = ''
  v$.value.inputValue.$model = inputValue.value
  emit('clear')
}
const change = () => {
  v$.value.$touch()
  emit('change', inputValue.value)
  emit('check')
}
const input = () => {
  // inputValue.value = v$.value.inputValue.$model
  v$.value.$touch()
  emit('check')
}
const focus = () => {
  showLabel()
  emit('focus')
}
const blur = () => {
  if (props.modelValue !== null && props.modelValue !== undefined) {
    fieldFocus.value =
      (props.modelValue != null && props.modelValue != undefined && props.modelValue !== '') ||
      props.modelValue instanceof Date
  } else {
    fieldFocus.value = false
  }
  emit('blur', !fieldFocus.value)
}
// const manageBlur = () => {
//   if (props.modelValue !== null && props.modelValue !== undefined) {
//     fieldFocus.value =
//       (props.modelValue != null && props.modelValue != undefined && props.modelValue !== '') ||
//       props.modelValue instanceof Date
//   } else {
//     fieldFocus.value = false
//   }
// }
const focusDatePicker = togglePopover => {
  showLabel()
  togglePopover()
}
</script>

<style lang="scss">
@import 'v-calendar/dist/style.css';

.sp-input-field {
  padding: 0;
  margin-bottom: 15px;

  &.full-width {
    width: 100%;
  }

  .sp-input-error {
    margin: $spacing-xs $spacing-sm;
  }

  &.bg-white input {
    background-color: var(--white);
  }

  &.bg-grey input {
    background-color: var(--bg-grey);
    font-size: pxToRem(15px);
    font-weight: 400;
  }

  &.autocomplete-form,
  &.select-form {
    .activeInput {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &.radius-lg input {
    border-radius: $spacing-sm !important;
  }

  &.radius-md input {
    border-radius: $spacing-xs !important;
  }

  &.radius-sm input {
    border-radius: $spacing-xxs !important;
  }

  &.radius-sm input {
    border-radius: $spacing-xxs !important;
  }

  &.clearable-black .sp-input-clear-button {
    background-image: url('~/assets/img/svg/icons/close/ico-close-dark.svg');
  }
}

.sp-input-title-container {
  box-sizing: border-box;
  height: 28px;
  border: 0;
  margin: 0;
  color: var(--steel-grey);
}

.sp-input-title-text-container {
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  width: 100%;
  height: 28px;
  border: 0;
  margin: auto;
  color: var(--steel-grey);
}

.sp-input-title-text {
  font-family: 'Avenir';
  font-size: 12px !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--steel-grey) !important;
  color: rgba(113, 123, 139, 1);
  box-sizing: border-box;
  margin: auto;
  padding-left: $spacing-sm;

  &.u-c-primary {
    color: var(--primary) !important;
  }
}

.sp-input-field-div {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.sp-input-field textarea:focus,
.sp-input-field input:focus {
  outline: none;
}

.sp-input-field input:focus::placeholder {
  color: transparent;
}
.sp-input::placeholder {
  font-family: 'Avenir';
}

.sp-input {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  border: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(151, 151, 151, 0.1);
  caret-color: var(--primary);
  border-radius: 3px;
  font-family: 'Avenir';
  font-size: pxToRem(12px) !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--steel-grey);
  &.error {
    margin-top: 0;
    margin-bottom: $spacing-sm;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--bg-grey) inset !important;
  }
}

.sp-input[data-input-type='password'] {
  padding-right: 50px;
}

.sp-input[clearable='true'] {
  padding-right: 40px;
}

.sp-input.has-icon {
  padding-left: 40px !important;
  background-repeat: no-repeat;
  background-position: 6px center;
}

.activeInput {
  color: var(--dark-blue) !important;
  font-size: pxToRem(12px) !important;
  font-weight: 500 !important;
}

.sp-input.error {
  background-color: rgba(var(--grapefruit-rgb), 0.1);
}

.sp-input-field div .error {
  font-size: 13px;
  font-family: Avenir;
  color: var(--grapefruit);
  float: left;
}

.sp-input-icon {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  height: 40px;
  padding-left: 4px;
  position: absolute;
  width: 40px;
}

.sp-input-password-button {
  background-image: url('https://d3brsr9pdomwt0.cloudfront.net/illustrations/passwordhiden.svg');
  background-position: -7px center;
  background-repeat: no-repeat;
  color: var(--blue);
  height: 40px;
  right: 5px;
  top: 0;
  position: absolute;
  width: 40px;
}

.sp-input-password-button[data-input-type='password'] {
  background-position: -48px center;
}

.sp-input-clear-button {
  background-image: url('~/assets/img/svg/icons/close/ico-close.svg');
  background-position: -7px center;
  background-repeat: no-repeat;
  color: var(--black);
  height: 40px;
  right: -10px;
  top: 0;
  position: absolute;
  width: 40px;
}

// input:invalid {
//     background-color: rgba(var(--grapefruit, 0.1));
//   }
</style>
