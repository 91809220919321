import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'

export function useTagsApi() {
  const { fetcher, backendUrl } = useCustomFetcher()

  const getStocksTags = async () => {
    const url = `${backendUrl}stocks-tags`
    return fetcher
      .get(url)
      .then(response => {
        return response.stocks_tags
      })
      .catch(e => {
        console.log(e)
        throw e
      })
  }
  return {
    getStocksTags,
  }
}
