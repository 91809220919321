import { useConfigStore } from '@/stores/config'
import { useUtmStore } from '@/stores/utm'

export default defineNuxtPlugin(() => {
  const configStore = useConfigStore()
  const utmStore = useUtmStore()
  const savedStateUtm = useCookie('x-utm', { maxAge: 60 * 60 * 24 * 3, secure: false })
  const savedStateConfig = useCookie('x-config', { maxAge: 60 * 60 * 24 * 3, secure: false })

  if (savedStateUtm.value?.list) {
    utmStore.add(savedStateUtm.value.list)
  }
  if (savedStateConfig.value?.showVAT !== undefined && savedStateConfig.value?.showVAT !== null) {
    configStore.add(savedStateConfig.value)
  }
  configStore.$onAction(({ name, store, after, onError }) => {
    after(() => {
      try {
        const configStoreData = store.$state
        delete configStoreData.pageData
        savedStateConfig.value = JSON.stringify(configStoreData)
      } catch (e) {
        console.error('Error for saving in cookies :', e)
      }
    })
    onError(error => {
      console.error(`Action execution error "${name}":`, error)
    })
  })
  utmStore.$onAction(({ name, store, after, onError }) => {
    after(() => {
      try {
        const utmStoreData = store.$state
        savedStateUtm.value = JSON.stringify(utmStoreData)
      } catch (e) {
        console.error('Error for saving in cookies :', e)
      }
    })
    onError(error => {
      console.error(`Action execution error "${name}":`, error)
    })
  })
})
