import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'
import { useCartMapper } from '@/composables/Api/Mappers/useCartMapper'

export function useCartApi() {
  const { fetcher, backendUrl } = useCustomFetcher()
  const { basicMapping, cartMapping, resumeMapping } = useCartMapper()

  const updateStatus = async (userUid, companyUid, cartUid, status, mapping = basicMapping) => {
    try {
      const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
      const config = {
        headers: {
          Mapping: mapping,
        },
      }
      await fetcher.put(url, { status }, config)
      return true
    } catch (e) {
      return false
    }
  }

  const get = async (userUid, companyUid, cartUid, mapping = cartMapping) => {
    const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher.get(url, config).then(response => response.cart)
  }

  const validateCoupon = async coupon => {
    const url = `${backendUrl}coupons/${coupon}/valid`
    return fetcher.get(url).then(response => response)
  }

  const check = async (userUid, companyUid, cartUid, mapping = cartMapping) => {
    const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/check`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher.get(url, config).then(response => response.cart)
  }

  const deleteCart = async (userUid, companyUid, cartUid) => {
    try {
      const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
      return fetcher.delete(url)
      // return true
    } catch (e) {
      console.log('error deleting cart', e)
      return false
    }
  }

  const create = async ({ userUid, companyUid, merchants, coupons, utm }, mapping = cartMapping) => {
    let cartUid = null
    try {
      if (merchants.length > 0) {
        const cartBaseUrl = `${backendUrl}companies/${companyUid}/users/${userUid}/`
        const url = `${cartBaseUrl}cart/stocks`
        const cartMerchants = []
        const config = {
          headers: {
            Mapping: mapping,
          },
        }
        merchants.forEach(merchant => {
          const stocks = []
          merchant.items.forEach(item => {
            stocks.push({
              stock_uid: item.uid,
              quantity: item.quantity,
              price: item.stock?.price,
            })
          })
          cartMerchants.push({
            stocks,
            delivery_method: merchant.deliveryMethod,
          })
        })
        const cartResponse = await fetcher
          .post(url, { merchants: cartMerchants, ...utm }, config)
          .then(response => {
            if (response.success) {
              cartUid = response.cart.uid
              if (coupons.length > 0) {
                const couponUrl = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/coupons`
                const requests = []
                coupons.forEach(coupon => {
                  requests.push(fetcher.post(couponUrl, { coupon_code: coupon.code }, config))
                })
                return Promise.all(requests)
              }
              return Promise.resolve([response])
            }
            throw new Error(response.message)
          })
          .then(responses => {
            // Make sure to get the most up to date response
            // by getting the cart response with most used coupons
            const response = responses.sort((a, b) => {
              return b.data.cart.coupons.length - a.data.cart.coupons.length
            })
            if (response[0].success) {
              return response[0].cart
            }
            deleteCart(userUid, companyUid, cartUid)
            throw new Error('bad_parameter')
          })
        return cartResponse
      }
    } catch (e) {
      if (cartUid !== null) {
        deleteCart(userUid, companyUid, cartUid)
      }
      throw e
    }

    return null
  }

  const resume = async (resumeUid, mapping = resumeMapping) => {
    const url = `${backendUrl}/carts/${resumeUid}/resume`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher.get(url, config).then(response => response.cart)
  }

  const order = async (userUid, companyUid, cartUid, payload, mapping = cartMapping) => {
    const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/order`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher.post(url, payload, config).then(response => response.cart)
  }

  const quote = async (userUid, companyUid, cartUid, payload = {}, mapping = cartMapping) => {
    const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/quote`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher.post(url, payload, config).then(response => response.cart)
  }

  const setAddress = async (userUid, companyUid, cartUid, deliveryAddressUid, mapping = cartMapping) => {
    const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher
      .put(url, { cart: { delivery_address_uid: deliveryAddressUid } }, config)
      .then(response => response.cart)
  }

  const getCartLocation = async (userUid, companyUid, cartUid, location, mapping = cartMapping) => {
    const url = `${backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher
      .get(url, { params: { 'location[lat]': location.lat, 'location[lon]': location.lng } }, config)
      .then(response => response.cart)
  }

  return {
    get,
    check,
    updateStatus,
    validateCoupon,
    create,
    resume,
    order,
    quote,
    setAddress,
    getCartLocation,
    deleteCart,
  }
}
