import { useGTMAnalytics } from '@/composables/Analytics/useGTMAnalytics'
import { useProbanceAnalytics } from '@/composables/Analytics/useProbanceAnalytics'

export function useAnalytics() {
  const activeAnalytics = [useGTMAnalytics(), useProbanceAnalytics()]

  const analytics = {
    sendTagEvent: (name, params = {}) => {
      try {
        activeAnalytics.forEach(analytic => {
          analytic.sendTagEvent(name, params)
        })
      } catch (error) {
        // TODO add sentry
        console.log('error sendTagEvent', error, name, params)
      }
    },
    sendEvent: (name, params = {}) => {
      try {
        activeAnalytics.forEach(analytic => {
          analytic.sendEvent(name, params)
        })
      } catch (error) {
        // TODO add sentry
        console.log('error sendEvent', error, name, params)
      }
    },
    sendScreen: (name, params = {}) => {
      try {
        activeAnalytics.forEach(analytic => {
          analytic.sendScreen(name, params)
        })
      } catch (error) {
        // TODO add sentry
        console.log('error sendScreen', error, name, params)
      }
    },
  }

  const screens = {
    CART: 'cart',
    ORDER_CONFIRMATION: 'OrderConfirmation',
    ADD_TO_CART: 'AddToCart',
    CART_CONFIRMATION_MODAL: 'AddToCartConfirmation',
    CGU: 'CGU',
    CGV: 'CGV',
    HOME: 'Home',
    CLUB_HOME: 'ClubHome',
    SIGNUP_SEARCH_COMPANY: 'InputCompanySearchCompany',
    SIGNUP_SEARCH_COMPANY_RESULTS: 'InputCompanySearchCompanyResults',
    SIGNUP_COMPANY_DETAILS: 'SubscriptionCompanyDetails',
    SIGNUP_PROMO_CODE: 'SubscriptionPromoCode',
    SIGNUP_OWNER_DETAILS: 'SubscriptionOwnerDetails',
    LOGIN: 'Login',
    LOGIN_CART: 'LoginCart',
    MARKETPLACE_SEARCH: 'MarketplaceSearch',
    MARKETPLACE_BRAND: 'MarketplaceBrand',
    MARKETPLACE_MERCHANT: 'MarketplaceMerchant',
    MARKETPLACE_MERCHANT_PRODUCTS: 'MarketplaceMerchantProducts',
    MARKETPLACE_MERCHANT_SEARCH: 'MarketplaceMerchantSearch',
    MARKETPLACE_MERCHANT_ABOUT_US: 'MarketplaceMerchantAboutUs',
    MARKETPLACE_CATEGORY: 'MarketplaceCategory',
    MARKETPLACE_NEW_PRODUCTS: 'MarketplaceNewProducts',
    MARKETPLACE_BEST_SELLERS: 'MarketplaceBestSellers',
    MARKETPLACE_BEST_DEALS: 'MarketplaceBestDeals',
    MARKETPLACE_SPECIAL_DISCOUNTS: 'MarketplaceSpecialDiscounts',
    ORDER_DETAILS: 'OrderDetail',
    PASSWORD: 'Password',
    PRODUCT_DETAIL: 'ProductDetail',
    ACTIVATION_CODE: 'ActivationCode',
    RESET_PASSWORD: 'ResetPassword',
    FILTER_BRANDS: 'SortFilterBrands',
    FILTER_CATEGORIES: 'SortFilterCategories',
    FILTER_DISTANCE: 'SortFilterDistance',
    TRANSACTIONS: 'Transactions',
    QUOTES: 'Quotes',
    DOWNLOAD: 'Download',
    BRANDS: 'Brands',
    PRESS: 'Press',
    PRESS_ARTICLE: 'PressArticle',
    JOBS: 'Jobs',
    JOB_POST: 'JobPost',
    PRIVACY: 'Privacy',
    STRIPE_ONBOARDING: 'StripeOnboarding',
    CHECKOUT: 'Checkout',
    CART_SUBSCRIBE: 'CartSubscribe',
    SUPPORT: 'Support',
    SUPPORT_ARTICLE: 'SupportArticle',
    BTOB: 'BtoB',
    CONTEST: 'Contest',
    PRICES: 'Prices',
    QRCODE: 'QRCode',
    LANDING: 'Landing',
  }

  const pageTemplate = {
    HOMEPAGE: 'homepage',
    CLUB_HOMEPAGE: 'club homepage',
    PRO_HOMEPAGE: 'pro homepage',
    CATEGORY: 'category page',
    BRAND: 'brand page',
    BRANDS_LIST: 'brands list page',
    LANDING: 'landing page',
    CONTACT: 'contact page',
    PRODUCT: 'product detail page',
    PRODUCTS_LIST: 'products list page',
    CART: 'cart page',
    CHECKOUT: 'checkout page',
    ORDER_CONFIRMATION: 'validation page',
    LOGIN: 'login page',
    DASHBOARD: 'dashboard page',
    ERROR: 'error page',
    BLOG: 'blog page',
    BLOG_ARTICLE: 'blog article page',
    MERCHANT: 'merchant page',
    ORDER: 'order page',
    ORDERS_LIST: 'orders list page',
    ISSUES: 'order issues page',
    PRESS: 'press page',
    PRESS_ARTICLE: 'press article page',
    PRO_BUNDLE: 'pro bundle page',
    PRO_SERVICE: 'pro service page',
    PLANS: 'plans page',
    ALERTS: 'alerts page',
    STRIPE_ONBOARDING: 'stripe onboarding page',
    PARTNER: 'partner page',
    PRO: 'pro page',
    STOCK: 'stock page',
    DOWNLOAD: 'download page',
    SIGNUP: 'signup page',
    SUPPORT: 'support page',
    LEGAL: 'legal page',
    MISSION: 'mission page',
    QRCODE: 'qrcode page',
    LP_INTERNATIONAL: 'LP de lancement',
  }

  const events = {
    ADD_TO_CART: 'add_to_cart',
    LOGIN_STATUS: 'login_status',
    SUBMIT_APPLICATION: 'SubmitApplication',
    TRANSACTION_READY: 'transaction_ready',
    PURCHASE_FB: 'PurchaseFB',
    VIEW_PRODUCT: 'viewProduct',
    PRODUCT_ALREADY_SAVED: 'product_already_saved',
    SEARCH_IN_MKP: 'search_in_mkp',
    SEARCH_IN_MKP_MERCHANT: 'search_in_mkp_merchant',
    OPEN_BURGER_MENU_CATEGORIES: 'openBurgerMenuCategories',
    CLOSE_BURGER_MENU: 'closeBurgerMenu',
    BEGIN_CHECKOUT: 'begin_checkout',
    PURCHASE: 'purchase',
    GENERATE_LEAD: 'generate_lead',
    VIEW_ITEM: 'view_item',
    SELECT_ITEM: 'select_item',
    VIEW_CART: 'view_cart',
    LOGIN: 'login',
    LOGOUT: 'logout',
    SIGN_UP: 'sign_up',
    REMOVE_FROM_CART: 'remove_from_cart',
    TAG_PLAN: {
      MKP: {
        CLICK_CART: 'click_view_cart',
        CLICK_PRODUCT: 'click_product_card',
        CLICK_BUY: 'click_buy',
        CONFIRM_BUY: 'confirm_buy',
        CLICK_AD: 'click_ad',
        SORT: 'sort',
        FILTER: 'filter',
      },
      CART: {
        INITIATE_CHECKOUT_FB: 'initiateCheckoutFB',
        CLICK_ADD_PROMO_CODE: 'click_add_promo_code',
        UPDATE_PRODUCT_QUANTITY: 'update_cart_product_quantity',
        CLICK_PAY_CART: 'click_pay_cart',
        CLICK_SUBMIT_CART: 'click_submit_pay',
        CLICK_REMOVE_PRODUCT_FROM_CART: 'click_remove_product_from_cart',
        TICK_CGV: 'tick_accept_cgv',
      },
      ORDER: {
        TOGGLE_PURCHASES_SALES: 'toggle_purchases_sales',
        CLICK_CANCEL_ORDER: 'click_cancel_order',
        CLICK_CANCEL_ORDER_CONFIRMATION: 'click_cancel_order_confirmation',
        CLICK_OPEN_MAP: 'click_open_map',
        UPDATE_ORDER_STATUS: 'update_order_status',
      },
      SEARCH: {
        CLICK_SEARCH_BAR: 'click_search_bar',
        CLICK_FILTER_DISTANCE: 'click_filter_distance',
        CLICK_FILTER_BRANDS: 'click_filter_brands',
        CLICK_FILTER_CATEGORIES: 'click_filter_categories',
        CLICK_BRAND: 'click_brand_filter',
        CLICK_CATEGORY: 'click_category_filter',
        CLICK_LOCATION: 'click_location_filter',
        CLICK_DISTANCE: 'click_distance_filter',
        CLICK_RESET_DISTANCE: 'click_reset_distance_filter',
      },
      BOOST_MY_SALES: {
        FORM_SENT: 'boost_my_sales_form_sent',
      },
    },
    CLICK_VIPROS_OFFER_DETAILS: 'click_vipros_offer_details',
  }

  return {
    analytics,
    screens,
    pageTemplate,
    events,
  }
}
