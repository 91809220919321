import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'
import { useCompanyMapper } from '@/composables/Api/Mappers/useCompanyMapper'
import axios from 'axios'
import { useUserStore } from '@/stores/user'

export function useCompanyApi() {
  const { fetcher, backendUrl, backendUrlV2 } = useCustomFetcher()
  const { companyMapping } = useCompanyMapper()

  const findWithSiren = async siren => {
    const fSiren = siren.replace(/\s/g, '')
    const url = `${backendUrl}companies/siren/${fSiren}`
    return fetcher.get(url).then(response => response.company)
  }

  const findWithName = async name => {
    const url = `${backendUrl}companies/search`
    const config = {
      params: {
        name,
      },
    }
    return fetcher.get(url, config).then(response => response.companies)
  }

  const getCompanies = async (filter, mapping = companyMapping) => {
    const url = `${backendUrlV2}companies`
    const config = {
      // TODO send full only for admin user, need change in company selector
      params: {
        // full: true,
      },
      headers: {
        Mapping: mapping,
      },
    }
    if (filter?.kind) config.params.kind = filter.kind
    if (filter?.items_per_page) config.params.per_page = filter.items_per_page
    if (filter?.page) config.params.page = filter.page
    if (filter?.search) config.params.search = filter.search
    if (filter?.full) config.params.full = filter.full
    return fetcher.get(url, config).then(response => response)
  }

  const subscribe = async company => {
    const subscribeUrl = `${backendUrl}companies`
    return fetcher.post(subscribeUrl, { company }).then(response => response.company)
  }

  const subscribeStripeVerification = async (companyUid, formData) => {
    const { bearerToken } = useUserStore()
    const subscribeUrl = `${backendUrl}companies/${companyUid}/verification`
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: bearerToken,
      },
    }
    return axios.post(subscribeUrl, formData, config).then(response => response.data.success)
  }

  const subscribeStripe = async (companyUid, purchase) => {
    const subscribeUrl = `${backendUrl}companies/${companyUid}/company_purchases`
    const payload = {
      company_purchase: purchase,
    }
    return fetcher.post(subscribeUrl, payload).then(response => response)
  }

  return {
    findWithSiren,
    findWithName,
    getCompanies,
    subscribe,
    subscribeStripeVerification,
    subscribeStripe,
  }
}
