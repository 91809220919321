import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'

export function useCategoryApi() {
  const { fetcher, backendUrlV2 } = useCustomFetcher()

  const getCategories = async (filters = [], forMkp = true) => {
    const url = `${backendUrlV2}marketplace/web/categories/lightweight_tree`
    const config = {
      params: {
        ...(forMkp && { with_mkp_products: forMkp }),
      },
    }
    if (filters && filters.length > 0) {
      const brandsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'BRAND') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (brandsFilter.length > 0) config.params.brand_uid = brandsFilter.join()

      const merchantsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'MERCHANT') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (merchantsFilter.length > 0) {
        config.params.merchant_uids = merchantsFilter.join()
      }

      const companyFilter = filters.find(f => f.type === 'COMPANY')
      if (companyFilter) config.params.company_uid = companyFilter.value

      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params.product_query = keywordsFilter.value

      const scopeFilter = filters.find(f => f.type === 'SCOPE')
      if (scopeFilter) config.params[scopeFilter.query] = scopeFilter.value

      const tagsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'TAGS') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (tagsFilter.length > 0) {
        config.params.tags = tagsFilter.join()
      }

      const merchantTagsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'MERCHANT_TAGS') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (merchantTagsFilter.length > 0) {
        config.params.merchant_tags = merchantTagsFilter.join()
      }
      const displayAllFilter = filters.find(f => f.type === 'DISPLAY_ALL')
      if (displayAllFilter) {
        config.params.display_all = displayAllFilter.value
      }
    }
    return fetcher.get(url, config).then(response => {
      return response
    })
  }

  const getCategoryBySlug = async slug => {
    const url = `${backendUrlV2}marketplace/web/categories/${slug}`
    const config = {
      use_native: true,
      // headers: {
      //   Mapping: mapping,
      // },
    }
    return fetcher.get(url, config).then(response => response.category)
  }

  const getBurgerMenu = async () => {
    const url = `${backendUrlV2}marketplace/web/categories/burger_menu`
    const config = {
      use_native: true,
      // headers: {
      //   Mapping: mapping,
      // },
    }
    return fetcher.get(url, config).then(response => response)
  }

  return { getCategories, getCategoryBySlug, getBurgerMenu }
}
