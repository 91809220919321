<template>
  <NuxtLayout :key="layout" :name="layout">
    <div :key="locale" class="sp-error-page">
      <div class="sp-error-page-content">
        <h1>{{ $t('errorPage.error404Title') }}</h1>
        <div class="sp-error-page-image">
          <ImageLazy src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/404-header.svg" alt="404 not found" />
        </div>
        <div v-show="tld === 'FR'" class="sp-error-page-description">
          <a href="https://www.youtube.com/watch?v=-xUfcJS0aZk" target="_blank">
            <img
              src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/youtube-video.png"
              alt="StockPro youtube video"
            />
          </a>
          <div class="sp-error-page-description-content">
            <p>
              {{ $t('errorPage.error404Description') }}
            </p>
          </div>
        </div>
      </div>
      <ZendeskButton />
    </div>
  </NuxtLayout>
</template>

<script setup>
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'
import { useProAreaStore } from '@/stores/proArea'
import * as Sentry from '@sentry/vue'

const { tld, locale } = useCurrentLocale()

const clubStore = useClubStore()

const layout = computed(() => {
  const clubStore = useClubStore()
  const proAreaStore = useProAreaStore()
  return proAreaStore.getIsProDomain ? 'pro' : clubStore.layout
})

const props = defineProps({
  error: { type: Object, default: null },
})

if (props.error) {
  console.log('error', props.error)
  if (!useRuntimeConfig().public.SENTRY_DISABLED) {
    Sentry.captureException(props.error)
  }
}

onMounted(() => {
  if (clubStore.isClub) {
    const colors = clubStore.clubConfig?.theme?.colors
    if (colors) {
      const { setTheme } = useTheme()
      setTheme({ theme: { colors }, favicon: '/favicon.ico' })
    }
  }
})

try {
  const { defaultHead, defaultSeo } = useDefaultMetaData({
    customKey: 'error',
  })
  useHead(defaultHead())
  useSeoMeta(defaultSeo())
} catch (e) {
  console.log('error from error page', e)
}
</script>

<style lang="scss">
@import '@/assets/scss/partials/tpl/error-page';
</style>
