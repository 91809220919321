import { useClubStore } from '@/stores/club'
import { useI18nStore } from '@/stores/i18nStore'
import { useConfigStore } from '@/stores/config'
import { useCartStore } from '@/stores/cart'
import { useActivationStore } from '@/stores/activation'
import { useUserApi } from '@/composables/Api/useUserApi'

import { defineStore } from 'pinia'

export const useUserStore = defineStore('userStore', () => {
  const { country } = useI18nStore()

  const config = useRuntimeConfig()
  const cookieConfig = {
    httpOnly: false,
    sameSite: 'lax',
    secure: config.public.NODE_ENV === 'production',
    maxAge: 60 * 60 * 24 * 6,
    domain: `.${country?.host}`,
  }

  const zipCode = ref(null)
  const getZipCode = computed(() => zipCode.value)
  const setZipCode = value => {
    zipCode.value = value
  }

  const location = ref({
    lat: null,
    lng: null,
  })
  const getLocation = computed(() => location.value)
  const setLocation = value => {
    location.value = value
    localStorage.setItem('x-user-location', JSON.stringify(value))
  }

  const user = ref(null)
  const getUser = computed(() => {
    return user.value
  })

  const localToken = ref(null)
  const token = computed(() => {
    const authCookie = useCookie('auth.token', cookieConfig)
    return localToken.value || authCookie.value || null
  })
  const bearerToken = computed(() => {
    if (token.value && !token.value?.includes('Bearer')) {
      return `Bearer ${token.value}`
    }
    return token.value
  })
  const setLocalToken = token => {
    if (token) {
      const authCookie = useCookie('auth.token', cookieConfig)
      authCookie.value = token
      localToken.value = token
    } else {
      const authCookie = useCookie('auth.token', cookieConfig)
      authCookie.value = null
      localToken.value = null
    }
  }
  const isAuthenticated = computed(() => {
    if (import.meta.client) {
      return !!token.value && isValidUser.value
    } else {
      return !!token.value
    }
  })

  const name = computed(() => {
    return user.value?.first_name
  })
  const fullName = computed(() => {
    return user.value ? `${user.value?.first_name || ''} ${user.value?.last_name || ''}` : null
  })
  const phone = computed(() => {
    return user.value?.phone_number
  })
  const uid = computed(() => {
    return user.value?.uid
  })

  const company = computed(() => {
    return user.value?.company
  })
  const companyUid = computed(() => {
    return user.value?.company?.uid
  })
  const companyName = computed(() => {
    return user.value?.company?.name
  })
  const hasCompanyOptionsDashboard = computed(() => {
    return user.value?.company?.options?.includes('customer_dashboard')
  })
  const isValidUser = computed(() => {
    return user.value !== null && user.value !== undefined
  })
  const isFreemium = computed(() => {
    return user.value?.company?.status === 'free'
  })
  const isPro = computed(() => {
    return isValidUser.value && user.value?.role?.name !== 'Individual'
  })
  const isEmployee = computed(() => {
    return isValidUser.value && user.value?.role?.name === 'Employee'
  })
  const isOwner = computed(() => {
    return isValidUser.value && user.value?.role?.name === 'Owner'
  })
  const isAdmin = computed(() => {
    return isValidUser.value && user.value?.role?.name === 'Admin'
  })

  const clubs = computed(() => {
    // Filter out other group kind like GROUP
    return isValidUser.value && user.value?.company_groups?.filter(g => g.kind == 'MKP_CLUB' && g.key !== 'stockpro')
  })
  const isSalesRep = computed(() => {
    return user.value?.user_roles?.find(role => role?.company?.uid === user.value?.company?.uid)?.is_sales_rep || false
  })
  const isPriceTeam = computed(() => {
    return user.value?.user_roles?.find(role => role?.company?.uid === user.value?.company?.uid)?.is_price_team || false
  })
  const isSalesAdmin = computed(() => {
    return (
      user.value?.user_roles?.find(role => role?.company?.uid === user.value?.company?.uid)?.is_sales_admin || false
    )
  })

  const setUser = value => {
    user.value = value
    if (!zipCode.value && user.value?.addresses?.[0]?.zip_code) {
      setZipCode(user.value.addresses[0].zip_code)
    }
    if (!zipCode.value && user.value?.company?.addresses?.[0]?.zip_code) {
      setZipCode(user.value.company.addresses[0].zip_code)
    }
  }

  const login = async (_token, _user = {}) => {
    if (_token.includes('Bearer')) {
      _token = _token.split('Bearer ')[1]
    }
    setLocalToken(_token)
    const apiUser = await useUserApi().getMe()
    setUser(Object.assign(_user, apiUser))
    const activationStore = useActivationStore()
    activationStore.setPhone(null)
    activationStore.setEmailOrPhone(null)
    activationStore.setForgotPassword(false)

    const cartStore = useCartStore()
    // console.log(cartStore.setCartUid)
    if (_user?.cart_uid !== null && _user?.cart_uid !== undefined && typeof cartStore.setCartUid === 'function') {
      cartStore.setCartUid(_user?.cart_uid)
    } else if (typeof cartStore.setCartUid === 'function') {
      cartStore.setCartUid(null)
    }

    const configStore = useConfigStore()
    if (isPro.value) {
      configStore.add({ showVAT: false })
    } else {
      configStore.add({ showVAT: true })
    }

    const clubStore = useClubStore()
    if (clubStore.isClub) {
      clubStore.getClub()
    }
  }
  const logout = () => {
    return new Promise((resolve, reject) => {
      setLocalToken(null)
      try {
        clearUser()
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
  const clearUser = () => {
    setUser(null)
    reloadNuxtApp()
  }

  return {
    zipCode,
    getZipCode,
    setZipCode,
    location,
    getLocation,
    setLocation,
    user,
    getUser,
    name,
    fullName,
    phone,
    uid,
    company,
    companyUid,
    companyName,
    token,
    bearerToken,
    hasCompanyOptionsDashboard,
    isValidUser,
    isFreemium,
    isPro,
    isEmployee,
    isOwner,
    isAdmin,
    isAuthenticated,
    clubs,
    isSalesRep,
    isPriceTeam,
    isSalesAdmin,
    setUser,
    login,
    logout,
  }
})
