<template>
  <standard-modal
    :name="modalName"
    max-width="490px"
    :show-close-button="false"
    :element-test="elementTest + '-confirmation-modal'"
  >
    <template #title>
      <slot name="title" />
    </template>
    <template #content>
      <slot name="content" />
    </template>
    <template #options>
      <slot name="options">
        <slot name="cancel-button">
          <main-button
            :label="$t('actions.cancel')"
            tag="button"
            class-variant="btn-steel"
            :data-test-id="elementTest + '-cancel-button'"
            @click="onClose"
          />
        </slot>
        <slot name="confirm-button">
          <main-button
            :label="$t('actions.yes')"
            tag="button"
            :data-test-id="elementTest + '-confirm-button'"
            @click="onConfirm"
          />
        </slot>
      </slot>
    </template>
  </standard-modal>
</template>

<script setup>
import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import { useEmitterStore } from '@/stores/emitter'

const props = defineProps({
  modalName: { type: String, default: 'confirmationModal' },
  elementTest: { type: String, default: '' },
})
const emit = defineEmits(['on-confirm'])
const emitter = useEmitterStore()
const onConfirm = () => {
  emit('on-confirm')
  emitter.emit(`modalClose_${props.modalName}`, props.modalName)
}
const onClose = () => {
  emitter.emit(`modalClose_${props.modalName}`, props.modalName)
}
</script>
