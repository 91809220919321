import { useVuelidate } from '@vuelidate/core'

import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alpha,
  alphaNum,
  numeric,
  integer,
  decimal,
  email,
  sameAs,
  url,
} from '@vuelidate/validators'

export function useRules(customRules, key, value) {
  const rules = ref({
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    alpha,
    alphaNum,
    numeric,
    integer,
    decimal,
    email,
    sameAs,
    url,
  })

  const validations = computed(() => {
    const _value = {}
    for (const rule of customRules) {
      if (Array.isArray(rule)) {
        _value[rule[0]] = rules.value[rule[0]](rule[1])
      } else {
        _value[rule] = rules.value[rule]
      }
    }
    return { [key]: _value }
  })

  const v$ = useVuelidate(validations.value, { [key]: value })

  return {
    v$,
    rules,
    validations,
  }
}
