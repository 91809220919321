import { useI18nStore } from '@/stores/i18nStore'
import { useCustomStoryblokApi } from '@/composables/Api/useCustomStoryblokApi'

import { defineStore } from 'pinia'

export const useProAreaStore = defineStore('proAreaStore', () => {
  const stories = ref([])
  const footerStories = ref([])
  const testimonies = ref([])
  const proArea = ref({})
  const isProDomain = ref(false)

  const getProFooterStories = computed(() => {
    return footerStories.value.map(x => {
      let slug = x.full_slug
      slug = slug
        ?.split('/')
        .slice(1, slug?.split('/').length)
        .filter(y => !y.includes('-folder'))
        .join('/')
      return {
        uuid: x.uuid,
        title: x.content?.alternate_title || x.content?.title || x.name,
        slug,
      }
    })
  })
  const getServices = computed(() => {
    if (proArea.value['espace-pro']) {
      return proArea.value['espace-pro'].services
    }
    return proArea.value.services
  })
  const getServicesParentList = computed(() => {
    if (proArea.value['espace-pro']?.services) {
      return Object.keys(proArea.value['espace-pro'].services)?.filter(x => x?.includes('folder'))
    } else if (proArea.value.services) {
      return Object.keys(proArea.value.services)?.filter(x => x?.includes('folder'))
    }
    return []
  })
  const getOffers = computed(() => {
    if (proArea.value['espace-pro']) {
      return proArea.value['espace-pro'].offers
    }
    return proArea.value.offers
  })
  const getOffersParentList = computed(() => {
    if (proArea.value?.['espace-pro']) {
      return Object.keys(proArea.value['espace-pro'].offers).filter(x => x.includes('folder'))
    }
    if (proArea.value?.offers) {
      return Object.keys(proArea.value.offers).filter(x => x.includes('folder'))
    }
  })
  const getIsProDomain = computed(() => {
    return isProDomain.value
  })
  const getProArea = computed(() => {
    return proArea.value
  })
  const getStoryBySlug = slug => {
    return stories.value.find(x => x.slug === slug)
  }
  const getStoryByUid = uid => {
    return stories.value.find(x => x.uuid === uid)
  }
  const getTestimonyByUid = uid => {
    return testimonies.value.find(x => x.uuid === uid)
  }

  const getServicesChildList = slug => {
    if (proArea.value['espace-pro']) {
      return Object.keys(proArea.value['espace-pro'].services[slug])
    }
    return Object.keys(proArea.value.services[slug])
  }

  const getOffersChildList = slug => {
    if (proArea.value['espace-pro']) {
      return Object.keys(proArea.value['espace-pro'].offers[slug])
    }
    if (proArea.value?.offers) {
      return Object.keys(proArea.value.offers[slug])
    }
  }

  const fetchProAreaStories = async () => {
    const i18nStore = useI18nStore()
    const config = useRuntimeConfig()
    const version = config.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft'
    // let date = new Date()
    try {
      const proStories = await useCustomStoryblokApi().get('', {
        version,
        starts_with: 'espace-pro',
        sort_by: 'name:asc',
        per_page: 100,
        // cv: config.public.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
        language: i18nStore.langCode(true),
      })
      const { stories } = proStories.data
      setProStories(stories)
      const testimonyStories = await useCustomStoryblokApi().get('', {
        version,
        starts_with: 'testimony',
        sort_by: 'name:asc',
        // cv: config.public.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
        language: i18nStore.langCode(true),
      })
      const { stories: testimonies } = testimonyStories.data
      setTestimonyStories(testimonies)
    } catch (e) {
      console.error(e)
    }
  }
  const fetchProAreaFooterStories = async () => {
    const config = useRuntimeConfig()
    const version = config.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft'
    const i18nStore = useI18nStore()
    // // let date = new Date()
    try {
      const proStories = await useCustomStoryblokApi().get('', {
        version,
        starts_with: 'espace-pro',
        sort_by: 'name:asc',
        filter_query: {
          available_in_footer: {
            is: true,
          },
        },
        per_page: 100,
        // cv: config.public.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
        language: i18nStore.langCode(true),
      })
      const { stories } = proStories.data
      setProFooterStories(stories)
    } catch (e) {
      console.error(e)
    }
  }
  const setProStories = value => {
    stories.value = value
    function recursiveSlug(obj, slug, i, slugs, story) {
      i++
      if (!obj[slug]) obj[slug] = {}
      if (slug.includes('-folder') || (i < slugs.length && slugs[i])) {
        recursiveSlug(obj[slug], slugs[i], i, slugs, story)
      } else {
        obj[slug] = story
      }
    }
    proArea.value = stories.value.reduce(
      (a, story) => {
        let slugs = story.full_slug?.split('/').slice(1)
        recursiveSlug(a, slugs[0], 0, slugs, story)
        return a
      },
      { home: {}, services: {}, offers: {}, tarifs: {} }
    )
  }
  const setProFooterStories = stories => {
    footerStories.value = stories
  }
  const setTestimonyStories = stories => {
    testimonies.value = stories
  }
  const setIsProDomain = () => {
    isProDomain.value = true
  }

  return {
    proArea,
    stories,
    footerStories,
    testimonies,
    isProDomain,
    getProFooterStories,
    getServices,
    getServicesParentList,
    getOffers,
    getOffersParentList,
    getIsProDomain,
    getProArea,
    getStoryBySlug,
    getStoryByUid,
    getTestimonyByUid,
    getServicesChildList,
    getOffersChildList,
    fetchProAreaStories,
    fetchProAreaFooterStories,
    setProStories,
    setProFooterStories,
    setTestimonyStories,
    setIsProDomain,
  }
})
