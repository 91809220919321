<template>
  <div v-if="price || isWebStock" class="stock-price-container">
    <div class="stock-price u-d-f u-ai-c" :class="classVariant" :data-test-id="elementTest + '-price-pblic'">
      <span class="stock-price-price" :data-test-id="elementTest + '-price-value'">{{
        localePrice(Number(price), false)
      }}</span>
      <div class="stock-price-label" :data-test-id="elementTest + '-price-label'">
        <div class="stock-price-label-batch" :data-test-id="elementTest + '-price-batch'">
          <span v-if="showSellingQuantity" :data-test-id="elementTest + '-price-sq'">{{ sellingQuantity }}</span>
          <span class="stock-price-label-ht" :data-test-id="elementTest + '-price-vat-value'">{{
            !isWebStock && showVAT && enabledVat ? $t('actions.VAT') : $t('actions.noVAT')
          }}</span>
        </div>
      </div>
    </div>

    <div v-if="ecoTax" class="stock-price-ecotax" :data-test-id="elementTest + '-price-ecotax'">
      <span v-html="$t('fields.ecoTax', { ecoTax: localePrice(Number(ecoTax), false) })" />
    </div>
  </div>
</template>

<script setup>
const { showVAT, localePrice } = usePrice()

defineProps({
  price: { type: [String, Number], default: 0 },
  isWebStock: { type: Boolean, default: false },
  sellingQuantity: { type: String, default: null },
  ecoTax: { type: [String, Number], default: null },
  classVariant: { type: String, default: null },
  showSellingQuantity: { type: Boolean, default: true },
  enabledVat: { type: Boolean, default: false },
  elementTest: { type: String, default: '' },
})
</script>

<style lang="scss">
.stock-price {
  gap: $spacing-xs;

  &-ecotax {
    color: var(--steel-grey);
    font-weight: 900;
    font-size: pxToRem(7px);
  }

  &-price {
    color: var(--dark-blue) !important;
    font-size: pxToRem(20px);
    font-weight: 800;

    @include mq($mq-lg) {
      font-size: pxToRem(15px);
    }
    @include mq($mq-md) {
      font-size: pxToRem(12px);
    }
  }

  &-label {
    display: flex;
    gap: $spacing-xxs;
    flex-direction: column;
    font-weight: 800;

    @include mq($mq-md) {
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
    }

    &-batch {
      display: flex;
      font-size: pxToRem(7px);
      text-transform: uppercase;
      color: var(--steel-grey);
      gap: $spacing-xxs;
      @include mq($mq-md) {
        font-size: pxToRem(8px);
      }
    }
  }

  &.font-size-xl {
    @include mq($mq-md) {
      flex-direction: row;
    }
    .stock-price-price {
      font-size: pxToRem(30px) !important;
      font-weight: 800;

      @include mq($mq-sm) {
        font-size: pxToRem(15px);
      }
    }

    .stock-price-label {
      @include mq($mq-md) {
        flex-direction: column;
        align-items: normal;
      }

      .stock-price-label-batch {
        font-size: pxToRem(12px);
        color: var(--steel-grey);

        @include mq($mq-sm) {
          font-size: pxToRem(12px);
        }
      }

      .stock-price-label-ht {
        font-size: pxToRem(12px);

        @include mq($mq-sm) {
          font-size: pxToRem(12px);
        }
      }
    }
  }

  &.font-size-lg {
    .stock-price-price {
      font-size: pxToRem(20px) !important;
    }
  }

  &.font-size-md {
    .stock-price-price {
      font-size: pxToRem(15px) !important;
    }
  }

  &.vat-inline {
    @include mq($mq-sm) {
      flex-direction: row !important;
    }
    .stock-price-label {
      flex-direction: row;
      justify-content: flex-start;
      gap: $spacing-xxs;
    }
    .stock-price-label * {
      font-weight: 400;
      font-size: pxToRem(15px) !important;
      color: var(--steel-grey);
    }
  }
}
</style>
