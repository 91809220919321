import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/app/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_KlVwwuJRCL from "/app/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_42RIGIx6e8 from "/app/node_modules/@nuxt/image/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import currentLocale_9jPOwUN77H from "/app/plugins/currentLocale.js";
import shared_actions_tj5hLBU1Er from "/app/plugins/shared-actions.js";
import lazy_load_zD1LThMoaz from "/app/plugins/lazy-load.js";
import local_storage_vYjxSuwtmD from "/app/plugins/local-storage.js";
import cookie_storage_xmYx0IQWiU from "/app/plugins/cookie-storage.js";
import cart_manager_F3g4fZAsAj from "/app/plugins/cart-manager.js";
import v_click_outside_client_KJFvJHv0Xl from "/app/plugins/v-click-outside.client.js";
import axeptio_kqwAdnYpJz from "/app/plugins/axeptio.js";
import custom_attr_zUhTpOJpEJ from "/app/plugins/custom-attr.js";
import error_handler_VFH3VvK7yG from "/app/plugins/error-handler.js";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import serverInit_KPq5m0gDLU from "/app/plugins/serverInit.js";
import user_service_mX2rhrgWuH from "/app/plugins/user-service.js";
import vuex_F8JCgj45hr from "/app/plugins/vuex.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  plugin_KlVwwuJRCL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_42RIGIx6e8,
  plugin_tbFNToZNim,
  currentLocale_9jPOwUN77H,
  shared_actions_tj5hLBU1Er,
  lazy_load_zD1LThMoaz,
  local_storage_vYjxSuwtmD,
  cookie_storage_xmYx0IQWiU,
  cart_manager_F3g4fZAsAj,
  v_click_outside_client_KJFvJHv0Xl,
  axeptio_kqwAdnYpJz,
  custom_attr_zUhTpOJpEJ,
  error_handler_VFH3VvK7yG,
  sentry_3AyO8nEfhE,
  serverInit_KPq5m0gDLU,
  user_service_mX2rhrgWuH,
  vuex_F8JCgj45hr
]