import { useUserStore } from '@/stores/user'
import { useCurrentLocale } from '@/composables/i18n/useCurrentLocale'

export default defineNuxtPlugin(async () => {
  const { country } = useCurrentLocale()
  const config = useRuntimeConfig()
  const cookieConfig = {
    httpOnly: false,
    sameSite: 'lax',
    secure: config.public.NODE_ENV === 'production',
    maxAge: 60 * 60 * 24 * 6,
    domain: `.${country?.host}`,
  }

  const userStore = useUserStore()
  const authCookie = useCookie('auth.token', cookieConfig)
  if (authCookie.value) {
    userStore.login(authCookie.value)
  }
})
