// import * as Sentry from '@sentry/vue'
import LocalCartProvider from '@/services/LocalCartProvider'
import { useCartItem } from '@/composables/useCartItem'
import { useCartApi } from '@/composables/Api/useCartApi'

export default class CartManager {
  constructor(store, country, config) {
    this.store = store
    this.provider = new LocalCartProvider(store)
    this.country = country
    this.config = config
  }

  get items() {
    return this.provider.items.map(item => useCartItem(item.stock, item.quantity))
  }

  get deliveryMethods() {
    return this.provider.deliveryMethods
  }

  get merchants() {
    this.checkVersion()
    const merchants = this.items.reduce((allMerchants, item) => {
      const deliveryIndex = this.deliveryMethods.findIndex(el => el.merchantUid === item.stock.company.uid)
      const merchant = allMerchants[item.stock.company.uid] || {
        company: item.stock.company,
        items: [],
        totalItems: 0,
        deliveryAvailable: item.stock.company.hasDelivery,
        pickupAvailable: item.stock.company.hasPickup,
        deliveryFee: this.deliveryFeeForCompany(item.stock.company),
        deliveryMethod: this.deliveryMethods[deliveryIndex],
      }
      merchant.totalItems += item.quantity
      merchant.items.push(item)
      allMerchants[item.stock.company.uid] = merchant
      return allMerchants
    }, {})
    return Object.values(merchants)
  }

  get coupons() {
    return this.provider.coupons
  }

  get totalItemsVAT() {
    if (this.isReady) {
      return Number(this.remoteCart?.total_items_price_vat)
    }
    return this.items.reduce((total, item) => total + item.quantity * item.stock.storePrice(true), 0)
  }

  get totalItemsNoVAT() {
    if (this.isReady) {
      return Number(this.remoteCart?.total_items_price)
    }
    return this.items.reduce((total, item) => total + item.quantity * item.stock.storePrice(false), 0)
  }

  get totalDiscountVAT() {
    if (this.isReady) {
      return Number(this.remoteCart?.total_discount_vat)
    }
    return this.coupons.reduce((total, coupon) => total + coupon.amount, 0)
  }

  get totalDiscountNoVAT() {
    if (this.isReady && this.remoteCart?.total_discount) {
      return Number(this.remoteCart?.total_discount)
    }
    return Number(this.totalDiscountVAT / this.country.VAT)
  }

  get totalDeliveryVAT() {
    if (this.isReady) {
      if (
        this.remoteCart?.merchants.find(merchant => {
          return merchant?.delivery?.amount === null
        })
      ) {
        return null
      }
      return Number(this.remoteCart?.total_delivery_price_vat)
    }
    return this.merchants
      .filter(merchant => merchant.deliveryMethod?.type !== 'PICK_UP')
      .reduce((total, merchant) => total + merchant.deliveryFee, 0)
  }

  get totalDeliveryNoVAT() {
    if (this.isReady) {
      if (
        this.remoteCart?.merchants.find(merchant => {
          return merchant?.delivery?.amount === null
        })
      ) {
        return null
      }
      return Number(this.remoteCart?.total_delivery_price)
    }
    return this.merchants
      .filter(merchant => merchant.deliveryMethod?.type !== 'PICK_UP')
      .reduce((total, merchant) => total + merchant.deliveryFee, 0)
  }

  get totalVAT() {
    if (this.isReady) {
      return Number(this.remoteCart?.discount_price_vat)
    }
    return Math.max(this.totalItemsVAT + this.totalDeliveryVAT - this.totalDiscountVAT, 0)
  }

  get totalNoVAT() {
    if (this.isReady) {
      return Number(this.remoteCart?.total_price)
    }
    return Math.max(this.totalItemsNoVAT + this.totalDeliveryVAT - this.totalDiscountNoVAT, 0)
  }

  get totalVATWithoutDelivery() {
    if (this.isReady) {
      return Number(this.remoteCart?.discount_price_vat)
    }
    return Math.max(this.totalItemsVAT - this.totalDiscountVAT, 0)
  }

  get totalNoVATWithoutDelivery() {
    if (this.isReady) {
      return Number(this.remoteCart?.total_price)
    }
    return Math.max(this.totalItemsNoVAT, 0)
  }

  get VAT() {
    if (this.isReady) {
      return Number(this.remoteCart?.vat)
    }
    return this.totalVAT - this.totalNoVAT
  }

  get totalItems() {
    return this.items.reduce((total, item) => total + item.quantity, 0)
  }

  get cartUid() {
    return this.provider.cartUid
  }

  get remoteCart() {
    return this.provider.remoteCart
  }

  get isReady() {
    return this.provider.isReady
  }

  get version() {
    return this.provider.version
  }

  get canPay() {
    if (this.hasDelivery) {
      return this.isReady && this.remoteCart?.delivery_address !== null
    }
    return this.isReady
  }

  get hasDelivery() {
    return this.deliveryMethods.filter(dm => dm?.type !== 'PICK_UP')?.length > 0
  }
  get hasPickup() {
    return this.deliveryMethods.filter(dm => dm?.type === 'PICK_UP')?.length > 0
  }

  get needsDeliveryAddressForFee() {
    return (
      this.merchants.filter(m => {
        // No fee when deliveryMethod is not a pickup or self shipping,
        // except if cart allow free of charge shipping
        if (m.deliveryMethod?.type !== 'PICK_UP' && m.deliveryMethod?.type !== 'SELF_SHIPPING') {
          // If null we need address,
          // otherwise the cart for the merchant is high enough for free shipping
          return this.deliveryFeeForCompany(m.company) === null
        }
        return false
      }).length > 0
    )
  }

  get isDeliveryReady() {
    if (this.needsDeliveryAddressForFee) {
      return this.isReady && this.remoteCart?.delivery_address !== null
    }
    return true
  }

  get needsAccreditation() {
    return this.merchants.some(x => x.items.some(y => y.stock?.reference?.accreditation))
  }

  merchantTotal(uid, vat) {
    return this.items
      .filter(i => i.stock.company.uid === uid)
      .reduce((total, item) => total + item.quantity * item.stock.storePrice(vat), 0)
  }

  companyDeliveryFreeOfCharge(company, vat) {
    if (company.offersDeliveryFreeOfCharge) {
      const treshold = company.deliveryFreeOfCharge(vat)
      return Math.max(Number(treshold - this.merchantTotal(company.uid, vat)), 0)
    }
    return null
  }

  // Return company delivery fee by taking into account current merchant cart total
  deliveryFeeForCompany(company) {
    if (this.companyDeliveryFreeOfCharge(company, true) === 0) {
      return 0
    }
    return company.deliveryFee
  }

  checkVersion() {
    if (Number(this.version) < Number(this.config.public.LOCAL_DATA_VERSION)) {
      console.log('Clear cart because version mismatch')
      this.clearCart()
    }
  }

  addItem(item, resume = false) {
    this.checkVersion()
    this.provider.setVersion(this.config.public.LOCAL_DATA_VERSION)
    this.provider.setIsReady(false)
    if (item !== null && item !== undefined) {
      const index = this.items.findIndex(el => el.uid === item.uid)
      if (index === -1) {
        this.provider.addItem(item)
        const dIndex = this.deliveryMethods.findIndex(el => el.merchantUid === item.stock.company.uid)
        if (dIndex === -1) {
          const deliveryType = item.stock.company?.delivery_methods?.find(r => r !== 'PICK_UP')
          // Default delivery method is shipping if possible
          this.provider.setDeliveryMethod({
            merchantUid: item.stock.company.uid,
            type: item.stock.company.has_delivery ? deliveryType : 'PICK_UP',
            fee: deliveryType === 'SELF_SHIPPING' ? Number(item.stock.company.delivery_fee) : null,
          })
        }
      } else {
        item.quantity = this.mergeItemQuantity(item.quantity, this.items[index], !resume)
        this.provider.updateItem(item, index)
      }
    }
  }

  updateItem(item) {
    this.checkVersion()
    this.provider.setVersion(this.config.public.LOCAL_DATA_VERSION)
    this.provider.setIsReady(false)
    if (item !== null && item !== undefined) {
      const index = this.items.findIndex(el => el.uid === item.uid)
      if (index > -1) {
        this.provider.updateItem(item, index)
      }
    }
  }

  removeItem(item) {
    this.checkVersion()
    this.provider.setVersion(this.config.public.LOCAL_DATA_VERSION)
    this.provider.setIsReady(false)
    this.provider.removeItem(item)
    const index = this.items.findIndex(el => el.stock.company.uid === item.stock.company.uid)
    if (index === -1) {
      this.provider.removeDeliveryMethod(item.stock.company.uid)
    }
  }

  mergeItemQuantity(quantity, inCartItem, add = true) {
    let mergedQuantity = add ? quantity + inCartItem.quantity : Math.max(inCartItem.quantity, quantity)
    mergedQuantity = Math.min(mergedQuantity, inCartItem.stock.quantity)
    return mergedQuantity
  }

  setDeliveryMethod(delivery) {
    this.provider.setIsReady(false)
    this.provider.setDeliveryMethod(delivery)
  }

  addCoupon(coupon) {
    this.provider.setIsReady(false)
    if (coupon !== null && coupon !== undefined) {
      const index = this.coupons.findIndex(el => el.code.toUpperCase() === coupon.code.toUpperCase())
      if (index === -1) {
        this.provider.addCoupon(coupon)
      }
    }
  }

  clearCoupons() {
    this.provider.setIsReady(false)
    this.provider.clearCoupons()
  }

  // Sync local cart with backend
  // Delete potential backend cart and creates a new one
  async synchronize(user) {
    try {
      const Sentry = await import('@sentry/vue')
      if ((!user.uid || !user.companyUid) && !useRuntimeConfig().public.SENTRY_DISABLED) {
        Sentry.captureException(new Error(`error in cart synchronize ${this.cartUid} - user : ${JSON.stringify(user)}`))
      }
      if (this.cartUid !== null && this.cartUid !== undefined) {
        // To make sure we have a clean cart, we reset its status and delete it
        await useCartApi().updateStatus(user.uid, user.companyUid, this.cartUid, 'in_progress')
        await useCartApi().deleteCart(user.uid, user.companyUid, this.cartUid)
        this.clearRemoteCart()
      }
      // cookie from awin affiliation tracking
      const awcCookie = useCookie('awc', {
        maxAge: 60 * 60 * 24 * 30,
        secure: true,
      })
      return useCartApi()
        .create({
          userUid: user.uid,
          companyUid: user.companyUid,
          merchants: this.merchants,
          coupons: this.coupons,
          utm: awcCookie?.value ? { awc: awcCookie?.value } : {},
        })
        .then(cart => {
          if (cart !== null && cart !== undefined) {
            this.provider.setCartUid(cart.uid)
            this.provider.setRemoteCart(cart)
            this.provider.setIsReady(true)
            return cart
          }
          throw new Error('Invalid cart')
        })
        .catch(e => {
          console.log(e)
          throw e
        })
    } catch (e) {
      return e.message
    }
  }

  async order(user, payload) {
    if (this.remoteCart && this.remoteCart?.status !== 'in_progress') {
      // To make sure we have a clean cart, we reset its status and delete it
      await useCartApi().updateStatus(user.uid, user.companyUid, this.cartUid, 'in_progress')
    }
    return useCartApi()
      .order(user.uid, user.companyUid, this.cartUid, payload)
      .then(cart => {
        if (cart !== null && cart !== undefined) {
          this.provider.setCartUid(cart.uid)
          this.provider.setRemoteCart(cart)
          return cart
        }
        throw new Error('Invalid cart')
      })
  }

  async quote(user, payload = {}) {
    if (this.remoteCart && this.remoteCart?.status !== 'in_progress') {
      // To make sure we have a clean cart, we reset its status and delete it
      await useCartApi().updateStatus(user.uid, user.companyUid, this.cartUid, 'in_progress')
    }
    return useCartApi()
      .quote(user.uid, user.companyUid, this.cartUid, payload)
      .then(cart => {
        if (cart !== null && cart !== undefined) {
          this.provider.setCartUid(cart.uid)
          this.provider.setRemoteCart(cart)
          return cart
        }
        throw new Error('Invalid cart')
      })
  }

  resume(cart) {
    if (cart.stocks.length > 0) {
      cart.stocks.forEach(stock => {
        const item = useCartItem(stock, stock.current_cart_quantity)
        this.addItem(item, true)
      })
    }
  }

  async setAddress(user, address) {
    return useCartApi()
      .setAddress(user.uid, user.companyUid, this.cartUid, address.uid)
      .then(cart => {
        if (cart !== null && cart !== undefined) {
          this.provider.setRemoteCart(cart)
          this.provider.setIsReady(true)
          return cart
        }
        throw new Error('Invalid cart')
      })
      .catch(e => {
        throw e
      })
  }

  setIsReady(ready) {
    this.provider.setIsReady(ready)
  }

  clearRemoteCart() {
    this.provider.setIsReady(false)
    this.provider.clearRemoteCart()
  }

  clearCart() {
    this.provider.setIsReady(false)
    this.provider.clearCart()
    this.provider.setVersion(this.config.public.LOCAL_DATA_VERSION)
  }
}
