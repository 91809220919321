// inspired by https://github.com/developit/mitt

import { defineStore } from 'pinia'

export const useEmitterStore = defineStore('emitterStore', () => {
  const all = ref(new Map())

  const on = (name, handler = () => {}) => {
    const handlers = all.value.get(name)
    if (handlers) {
      handlers.push(handler)
    } else {
      all.value.set(name, [handler])
    }
  }
  const off = (name, handler) => {
    const handlers = all.value.get(name)
    if (handlers) {
      if (handler) {
        handlers.splice(handlers.indexOf(handler) >>> 0, 1)
      } else {
        all.value.set(name, [])
      }
    }
  }
  const emit = (name, evt) => {
    let handlers = all.value.get(name)
    if (handlers) {
      handlers.slice().map(handler => {
        handler(evt)
      })
    }

    handlers = all.value.get('*')
    if (handlers) {
      handlers.slice().map(handler => {
        handler(name, evt)
      })
    }
  }

  return {
    all,
    on,
    off,
    emit,
  }
})
