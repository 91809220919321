import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import { uniqBy } from '@/utils'
import { useReferenceApi } from '@/composables/Api/useReferenceApi'
import { useCustomStoryblokApi } from '@/composables/Api/useCustomStoryblokApi'
import { usePublicApi } from '@/composables/Api/usePublicApi'

const UPDATE_DELAY = 60 * 60 * 1000 // 60 minutes

export const useDynamicContentStore = defineStore('dynamicContent', {
  state: () => ({
    dynamicContentLocale: null,
    fullContentStored: false,
    marketplaceAds: {
      storySlug: 'marketplaceads',
      lastUpdate: null,
      data: null,
    },
    featuredProducts: {
      lastUpdate: null,
      data: [],
    },
    featuredBrands: {
      storySlug: 'featuredbrands',
      lastUpdate: null,
      data: [],
    },
    featuredSellers: {
      storySlug: 'featuredsellers',
      lastUpdate: null,
      data: [],
    },
    featuredInspirations: {
      storySlug: 'featuredinspirations',
      lastUpdate: null,
      data: [],
    },
    featuredSeasonalSelections: {
      storySlug: 'featuredseasonalselections',
      lastUpdate: null,
      data: [],
    },
    homeBanners: {
      storySlug: 'homebanners',
      lastUpdate: null,
      data: [],
    },
    news: {
      storySlug: 'news',
      lastUpdate: null,
      data: [],
    },
    homeContent: {
      storySlug: 'homecontent',
      lastUpdate: null,
      data: [],
    },
    counters: {
      co2: null,
      products: null,
    },
    blogArticles: {
      storySlug: 'BlogArticle',
      lastUpdate: null,
      data: [],
    },
    blogCategories: {
      storySlug: 'blogcategory',
      lastUpdate: null,
      data: [],
    },
    blogTags: {
      storySlug: 'blogtag',
      lastUpdate: null,
      data: [],
    },
    categoryBrands: {
      storySlug: 'CategoryBrand',
      lastUpdate: null,
      data: [],
    },
  }),

  getters: {
    getFullContentStored: state => state.fullContentStored,
    getBlogArticles: state =>
      state.blogArticles.data.filter(blog => {
        return blog?.content?.scheduled_publication_at
          ? DateTime.now() > DateTime.fromSQL(blog?.content?.scheduled_publication_at)
          : true
      }),
    getBlogArticleBySlug: state => slug => {
      return state.blogArticles.data.find(
        x => !!x.translated_slugs.find(y => y.path?.split('/')?.pop() === slug) || x.slug === slug
      )
    },
    getBlogArticleByUid: state => uid => {
      return state.blogArticles.data.find(x => x.uuid === uid)
    },
    getBlogCategoryByUid: state => uid => {
      return state.blogCategories.data.find(x => x.uuid === uid)
    },
    getBlogCategories: state => state.blogCategories.data,
    getBlogCategoryBySlug: state => slug => {
      return state.blogCategories.data.find(x => x.slug === slug)
    },
    getBlogArticlesByCategory: state => uid => {
      return state.blogArticles.data.filter(x => x.content.category === uid)
    },
    getBlogTags: state => state.blogTags.data,
    getBlogTagBySlug: state => slug => {
      return state.blogTags.data.find(x => x.slug === slug)
    },
    getBlogTagByUid: state => uid => {
      return state.blogTags.data.find(x => x.uuid === uid)
    },
    getBlogTagUidByTag: state => tag => {
      return state.blogTags.data.find(x => x.slug === tag)
    },
    getBlogArticlesByTagUid: state => tagUid => {
      return state.blogArticles.data.filter(x => x.content?.tags?.includes(tagUid))
    },
    getMarketplaceAds: state => state.marketplaceAds.data,
    getFeaturedProducts: state => state.featuredProducts.data,
    getFeaturedBrands: state => state.featuredBrands.data,
    getFeaturedSellers: state => state.featuredSellers.data,
    getFeaturedInspirations: state => state.featuredInspirations.data,
    getCategoryBrands: state => state.categoryBrands.data,
    featuredInspirationsSelected: state => url => {
      return state.featuredInspirations.data.find(x => {
        return url?.split('?')?.[0].includes(x.url?.split('?')?.[0]) || x.url.includes(url) || url.includes(x.url)
      })
    },
    getFeaturedSeasonalSelections: state => {
      return state.featuredSeasonalSelections.data.filter(x => !x.menu)
    },
    getFeaturedSeasonalSelectionsMenu: state => {
      return state.featuredSeasonalSelections.data.find(x => x.menu)
    },
    getTopBanner: state => {
      let topBanner = state.homeBanners.data?.find(
        b =>
          b?.target === 'event' &&
          b?.global_top_banner &&
          DateTime.now() < DateTime.fromSQL(b?.end_date) &&
          (b?.start_date ? Date.now() > DateTime.fromSQL(b?.start_date) : true)
      )
      if (topBanner) {
        return topBanner
      }
    },
    getHomeBanner: state => currentUser => {
      let eventBanner = state.homeBanners.data.find(
        b =>
          b.target === 'event' &&
          !b.global_top_banner &&
          DateTime.now() < DateTime.fromSQL(b?.end_date) &&
          (b?.start_date ? Date.now() > DateTime.fromSQL(b?.start_date) : true)
      )
      if (eventBanner) {
        return eventBanner
      } else if (currentUser.isPro && state.homeBanners.data.find(b => b.target === 'pro')) {
        return state.homeBanners.data.find(b => b.target === 'pro')
      } else if (
        !currentUser.isPro &&
        currentUser.isAuthenticated &&
        state.homeBanners.data.find(b => b.target === 'individual')
      ) {
        return state.homeBanners.data.find(b => b.target === 'individual')
      } else {
        return state.homeBanners.data.find(b => b.target === 'default')
      }
    },
    getNews: state => state.news.data.filter(x => x.title),
    getHomeContent: state => position => {
      return state.homeContent.data.find(s => s.position === position)
    },
    needsUpdate: state => content => {
      if (state[content].lastUpdate === null) return true
      return Date.now() - state[content].lastUpdate >= UPDATE_DELAY
    },
    contentNeedsUpdate:
      state =>
      ({ contents, locale }) => {
        const needUpdate = []
        contents?.forEach(c => {
          const currentState = Object.values(state).find(x => x?.storySlug?.toLowerCase() === c?.toLowerCase())
          if (
            ((c || '').toLowerCase() === (currentState?.storySlug || '').toLowerCase() &&
              (currentState?.lastUpdate === null ||
                DateTime.now() - DateTime.fromISO(currentState?.lastUpdate) >= UPDATE_DELAY)) ||
            state.dynamicContentLocale !== locale
          ) {
            needUpdate.push(c)
          }
        })
        // console.log('needUpdate', needUpdate)
        return needUpdate.filter((x, i) => needUpdate?.indexOf(x) === i)
      },
  },
  actions: {
    async fetchDynamicContent(params) {
      try {
        let query = {}
        let locale = (params?.locale === 'fr' ? 'default' : params?.locale) || 'default'
        let contentTotalCount = null
        let page = 1
        if (params.page) page = params.page
        let perPage = 100
        if (params.perPage) perPage = params.perPage
        let storyQuery = params?.content
          ?.filter(content => content !== null)
          ?.map(element =>
            element.toLowerCase().includes('featuredbrand') ||
            element.includes('BlogArticle') ||
            element.includes('CategoryBrand')
              ? element
              : element.toLowerCase()
          )
          ?.join(',')
        if (storyQuery) {
          query.component = {
            in: storyQuery,
          }
        }
        await useCustomStoryblokApi()
          .get('', {
            filter_query: query,
            language: locale,
            page: page,
            per_page: perPage,
          })
          .then(response => {
            contentTotalCount = response?.headers?.total

            let mkpAds = response?.data?.stories
              ?.filter(x => x.full_slug.includes('marketplaceads'))
              .map(x => x?.content)
            if (mkpAds?.length) {
              this.marketplaceAds = {
                ...this.marketplaceAds,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.marketplaceAds.data, ...mkpAds] : mkpAds,
              }
            }

            let featuredBrand = response?.data?.stories
              ?.filter(x => x.full_slug.includes('featuredbrands'))
              .map(x => x?.content)
            if (featuredBrand?.length) {
              this.featuredBrands = {
                ...this.featuredBrands,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.featuredBrands.data, ...featuredBrand] : featuredBrand,
              }
            }

            let featuredSellers = response?.data?.stories
              ?.filter(x => x.full_slug.includes('featuredsellers'))
              .map(x => x?.content)
            if (featuredSellers?.length) {
              this.featuredSellers = {
                ...this.featuredSellers,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.featuredSellers.data, ...featuredSellers] : featuredSellers,
              }
            }

            let homeBanners = response?.data?.stories
              ?.filter(x => x.full_slug.includes('homebanners'))
              .map(x => x?.content)
            if (homeBanners?.length) {
              this.homeBanners = {
                ...this.homeBanners,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.homeBanners.data, ...homeBanners] : homeBanners,
              }
            }

            let homeContent = response?.data?.stories
              ?.filter(x => x.full_slug.includes('homecontent'))
              .map(x => x?.content)
            if (homeContent?.length) {
              this.homeContent = {
                ...this.homeContent,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.homeContent.data, ...homeContent] : homeContent,
              }
            }

            let featuredInspirations = response?.data?.stories
              ?.filter(x => x.full_slug.includes('featuredinspirations'))
              .map(x => x?.content)
            if (featuredInspirations?.length) {
              this.featuredInspirations = {
                ...this.featuredInspirations,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.featuredInspirations.data, ...featuredInspirations] : featuredInspirations,
              }
            }

            let featuredSeasonalSelections = response?.data?.stories
              ?.filter(x => x.full_slug.includes('featuredseasonalselections'))
              .map(x => x?.content)
            if (featuredSeasonalSelections?.length) {
              this.featuredSeasonalSelections = {
                ...this.featuredSeasonalSelections,
                lastUpdate: DateTime.now().toISO(),
                data:
                  page > 1
                    ? [...this.featuredSeasonalSelections.data, ...featuredSeasonalSelections]
                    : featuredSeasonalSelections,
              }
            }

            let news = response?.data?.stories?.filter(x => x.full_slug.includes('news')).map(x => x?.content)
            if (news?.length) {
              this.news = {
                ...this.news,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.news.data, ...news] : news,
              }
            }

            let blogArticles = response?.data?.stories?.filter(x => x.content.component === 'BlogArticle')
            if (blogArticles?.length) {
              this.blogArticles = {
                ...this.blogArticles,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.blogArticles.data, ...blogArticles] : blogArticles,
              }
            }

            let blogCategories = response?.data?.stories?.filter(x => x.full_slug.includes('blog/categories'))
            if (blogCategories?.length) {
              this.blogCategories = {
                ...this.blogCategories,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.blogCategories.data, ...blogCategories] : blogCategories,
              }
            }

            let blogTags = response?.data?.stories?.filter(x => x.full_slug.includes('blog/tags'))
            if (blogTags?.length) {
              this.blogTags = {
                ...this.blogTags,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.blogTags.data, ...blogTags] : blogTags,
              }
            }

            let categoryBrand = response?.data?.stories?.filter(x => x.full_slug.includes('categorybrand'))
            if (categoryBrand?.length) {
              this.categoryBrands = {
                ...this.categoryBrands,
                lastUpdate: DateTime.now().toISO(),
                data: page > 1 ? [...this.categoryBrands.data, ...categoryBrand] : categoryBrand,
              }
            }
          })
          .then(() => {
            if (locale) {
              this.dynamicContentLocale = locale === 'default' ? 'fr' : locale
            }
            // Recursive call until all pages are handled
            if (params.getAll) {
              if (contentTotalCount > page * 100) {
                return this.fetchDynamicContent({ ...params, page: page + 1 })
              } else {
                this.fullContentStored = true
              }
            }
          })
      } catch (error) {
        console.log('error fetchDynamicContent', error)
      }
    },
    async fetchMarketplaceAds(params) {
      const config = useRuntimeConfig()
      let locale = (params?.locale === 'fr' ? 'default' : params?.locale) || 'default'
      if (locale) {
        this.dynamicContentLocale = locale === 'default' ? 'fr' : locale
      }
      // let date = new Date()
      try {
        const response = await useCustomStoryblokApi().get('', {
          version: config.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft',
          starts_with: 'marketplaceads',
          sort_by: 'published_at:asc',
          // cv: config.public.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
          per_page: 100,
          language: locale,
        })
        this.marketplaceAds = {
          ...this.marketplaceAds,
          lastUpdate: DateTime.now().toISO(),
          data: response?.data.stories
            .filter(ads => (config.public.STORYBLOK_ENV !== 'production' ? ads.content?.active_qa : true))
            .map(ads => ads.content),
        }
      } catch (e) {
        console.error(e)
      }
    },
    async fetchFeaturedProducts() {
      const results = await useReferenceApi().featured()
      this.featuredProducts = {
        ...this.featuredProducts,
        lastUpdate: DateTime.now().toISO(),
        data: results?.references,
      }
    },
    async fetchFeaturedBrands(params) {
      const config = useRuntimeConfig()
      let locale = (params?.locale === 'fr' ? 'default' : params?.locale) || 'default'
      // let date = new Date()
      try {
        const response = await useCustomStoryblokApi().get('', {
          version: config.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft',
          starts_with: 'featuredbrands',
          sort_by: 'published_at:asc',
          // cv: config.public.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
          per_page: 20,
          language: locale,
        })
        this.featuredBrands = {
          ...this.featuredBrands,
          lastUpdate: DateTime.now().toISO(),
          data: response?.data.stories.map(story => story.content),
        }
      } catch (e) {
        console.error(e)
      }
    },
    async fetchCounters() {
      if (this.counters.co2 === null) {
        try {
          const result = await usePublicApi().getInfos()
          this.counters = {
            products: result.mkp_total_count,
            co2: Math.ceil(result.co2_kilos / 1000),
          }
        } catch (e) {
          console.log(e)
          throw createError({ statusCode: 404, message: e.message })
        }
      }
    },
    setMarketplaceAds(ads) {
      this.marketplaceAds = {
        ...this.marketplaceAds,
        lastUpdate: DateTime.now().toISO(),
        data: ads,
      }
    },
    setFeaturedProducts(products) {
      this.featuredProducts = {
        ...this.featuredProducts,
        lastUpdate: DateTime.now().toISO(),
        data: products,
      }
    },
    setFeaturedBrands(brands) {
      this.featuredBrands = {
        ...this.featuredBrands,
        lastUpdate: DateTime.now().toISO(),
        data: brands,
      }
    },
    setFeaturedSellers(sellers) {
      this.featuredSellers = {
        ...this.featuredSellers,
        lastUpdate: DateTime.now().toISO(),
        data: sellers,
      }
    },
    setFeaturedInspirations(inspirations) {
      this.featuredInspirations = {
        ...this.featuredInspirations,
        lastUpdate: DateTime.now().toISO(),
        data: inspirations,
      }
    },
    setFeaturedSeasonalSelections(seasonalSelections) {
      this.featuredSeasonalSelections = {
        ...this.featuredSeasonalSelections,
        lastUpdate: DateTime.now().toISO(),
        data: seasonalSelections,
      }
    },
    setHomeBanners(banners) {
      this.homeBanners = {
        ...this.homeBanners,
        lastUpdate: DateTime.now().toISO(),
        data: banners,
      }
    },
    setNews(news) {
      this.news = {
        ...this.news,
        lastUpdate: DateTime.now().toISO(),
        data: news,
      }
    },
    setHomeContent(content) {
      this.homeContent = {
        ...this.homeContent,
        lastUpdate: DateTime.now().toISO(),
        data: content,
      }
    },
    setCounters(counters) {
      this.counters = counters
    },
    setBlogArticles(content) {
      this.blogArticles = {
        ...this.blogArticles,
        lastUpdate: DateTime.now().toISO(),
        data: content,
      }
    },
    addBlogArticles(content) {
      this.blogArticles = {
        ...this.blogArticles,
        lastUpdate: DateTime.now().toISO(),
        data: uniqBy([...content, ...this.blogArticles.data], 'uuid'),
      }
    },
    setBlogCategories(content) {
      this.blogCategories = {
        ...this.blogCategories,
        lastUpdate: DateTime.now().toISO(),
        data: content,
      }
    },
    setBlogTags(content) {
      this.blogTags = {
        ...this.blogTags,
        lastUpdate: DateTime.now().toISO(),
        data: content,
      }
    },
    setDynamicContentLocale(locale) {
      this.dynamicContentLocale = locale
    },
    setCategoryBrands(brands) {
      this.categoryBrands = {
        ...this.categoryBrands,
        lastUpdate: DateTime.now().toISO(),
        data: brands,
      }
    },
    setFullContentStored(isFullContentStored) {
      this.fullContentStored = isFullContentStored
    },
  },
})
