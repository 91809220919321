// import * as Sentry from '@sentry/vue'
import { useSnackbarStore } from '@/stores/snackbar'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook('app:error', async error => {
    console.log('Global error handler:', error)
    const snackbarStore = useSnackbarStore()
    const country = useCurrentLocale()
    if (error?.message.includes('dayIndex')) throw error
    snackbarStore.showSnackbar({
      color: 'grapefruit',
      message: nuxtApp.$i18n.t('backendError.unknown', { phone: country?.phoneNumber }),
    })
    if (!useRuntimeConfig().public.SENTRY_DISABLED) {
      const Sentry = await import('@sentry/vue')
      Sentry.captureException(error)
    }
    throw error
  })
})
