<template>
  <div v-if="!disabled" class="zendesk-button">
    <MainButton
      v-if="!zendesk"
      tag="a"
      :label="$t('zendesk.launcher.label')"
      class-variant="btn-primary u-c-dark-blue u-font-bold"
      icon-position="LEFT"
      @click="initAndOpenZendesk"
    >
      <div class="icon-zendesk u-bg-dark-blue" />
    </MainButton>
  </div>
</template>
<script>
import { useClubStore } from '@/stores/club'
import { useUserStore } from '@/stores/user'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import { useEmitterStore } from '@/stores/emitter'

export default {
  name: 'ZendeskButton',
  components: { MainButton },
  mixins: [],
  emits: ['loaded', 'open', 'close'],
  setup() {
    const config = useRuntimeConfig()
    return {
      config,
    }
  },
  data() {
    const config = useRuntimeConfig()
    return {
      zendesk: null,
      disabled: config.public.NODE_ENV !== 'production',
      fr: {
        zendesk: {
          chat: {
            title: 'Besoin d’aide ?',
            concierge: {
              name: 'Assistance en direct',
              title: 'Posez toutes vos questions',
            },
          },
        },
      },
      nl: {
        zendesk: {
          chat: {
            title: 'Hulp nodig ?',
            concierge: {
              name: 'Live ondersteuning',
              title: 'Stel al uw vragen',
            },
          },
        },
      },
    }
  },
  computed: {
    isClub() {
      const clubStore = useClubStore()
      return clubStore.isClub
    },
  },
  watch: {
    'userStore.user': {
      handler() {
        this.identifyZendesk()
      },
      deep: true,
    },
    '$i18n.locale'() {
      this.translateZendesk()
      this.identifyZendesk()
    },
  },
  mounted() {
    const emitter = useEmitterStore()
    emitter.on('openZendesk', this.initAndOpenZendesk)
  },
  beforeUnmount() {
    const emitter = useEmitterStore()
    emitter.off('openZendesk')
    this.zendesk?.$off('loaded')
    this.zendesk?.$off('open')
    this.zendesk?.$off('messenger:on')
  },
  methods: {
    async initAndOpenZendesk() {
      if (this.zendesk) {
        this.openZendesk()
      } else {
        await this.initZendesk()
        const interval = setInterval(() => {
          const el = document.getElementById('launcher')
          if (el) {
            this.openZendesk()
            clearInterval(interval)
          }
        }, 100)
      }
    },
    openZendesk() {
      this.translateZendesk()
      // this.zendesk.zE.activate()
      window.zE('messenger', 'open')
    },
    loadZendesk() {
      this.translateZendesk()
      this.zendesk.$on('loaded', () => {
        this.translateZendesk()
        this.identifyZendesk()
        if (this.isClub) {
          this.zendesk.hide()
        }
      })
      this.zendesk.$on('messenger:on', 'open', () => this.translateZendesk())
    },
    identifyZendesk() {
      if (window?.zE && this?.zendesk) {
        const userStore = useUserStore()
        const { tld, locale } = useCurrentLocale()
        if (userStore.isAuthenticated) {
          this.zendesk.identify({
            name: userStore.fullName,
            email: userStore.email,
            organization: userStore.isPro ? userStore.companyName : 'Individual',
          })
        } else {
          this.zendesk.logout()
        }
        let lang = [tld, locale]
        lang = lang.filter((x, i) => x && lang?.indexOf(x) === i)
        window.zE('messenger:set', 'conversationTags', [`lang: ${lang.join('-')}`])
      }
    },
    translateZendesk() {
      if (window.zE && this.$i18n.locale) {
        // window.zE('webWidget', 'setLocale', this.$i18n.locale)
        // window.zE('messenger:set', 'locale', this.$i18n.locale)
        this.zendesk.setLocale(this.$i18n.locale)
        this.zendesk.updateSettings({
          // window.zE('webWidget', 'updateSettings', {
          webWidget: {
            zIndex: 9999,
            color: {
              theme: '#1bb371',
            },
            launcher: {
              label: {
                '*': this.$t('zendesk.launcher.label'),
              },
            },
            chat: {
              title: { '*': this.$t('zendesk.chat.title') },
              concierge: {
                name: this.$t('zendesk.chat.concierge.name'),
                title: { '*': this.$t('zendesk.chat.concierge.title') },
              },
            },
            answerBot: {
              title: { '*': this.$t('zendesk.chat.title') },
              avatar: {
                name: {
                  '*': 'StockPro',
                },
              },
            },
          },
        })
      }
    },
    async initZendesk() {
      const config = useRuntimeConfig()
      const hideOnLoad = config.public.ZENDESK_HIDE_ON_LOAD || false
      const nonce = false
      const { country } = useCurrentLocale()
      const key = country?.zendeskKey?.[this.locale] || config.public.ZENDESK_KEY

      if (!this.disabled && (!key || key.length === 0)) {
        console.warn('Please enter a Zendesk Web Widget Key')
      }

      const disabledLogger = function (method, ...args) {
        console.log('Zendesk is disabled, you called:', { method, args })
      }

      if (this.disabled) {
        window.zE = disabledLogger
      }

      window.zESettings = {
        webWidget: {
          zIndex: 9999,
          color: {
            theme: '#1bb371',
          },
        },
      }

      const root = {}

      let isLoaded = false
      const emitter = useEmitterStore()

      root.$emit = emitter.emit
      root.$on = emitter.on
      root.$off = emitter.off
      root.isLoaded = () => isLoaded
      root.load = zendeskKey => {
        if (isLoaded) {
          return
        }

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.id = 'ze-snippet'
        const actualZendeskKey = zendeskKey || key
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + actualZendeskKey

        if (nonce) {
          script.setAttribute('nonce', nonce)
        }

        delete window.zE
        const first = document.getElementsByTagName('script')[0]
        first.parentNode.insertBefore(script, first)

        script.onload = event => {
          isLoaded = true

          if (hideOnLoad) {
            root.hide()
          }
          root.$emit('loaded', event)

          window.zE('messenger:on', 'open', () => {
            root.$emit('open')
          })

          window.zE('messenger:on', 'close', () => {
            root.$emit('close')
          })
        }
      }

      if (!this.disabled) {
        root.load(key)
      }

      root.hide = () => window.zE('webWidget', 'hide')
      root.show = () => window.zE('webWidget', 'show')
      root.logout = () => window.zE('webWidget', 'logout')
      root.identify = user => window.zE('webWidget', 'identify', user)
      root.prefill = user => window.zE('webWidget', 'prefill', user)
      // root.setLocale = locale => window.zE('webWidget', 'setLocale', locale)
      root.setLocale = locale => window.zE('messenger:set', 'locale', locale)
      root.updateSettings = settings => (window.zESettings = { ...window.zESettings, ...settings })
      root.clear = () => window.zE('webWidget', 'clear')
      root.updatePath = options => window.zE('updatePath', 'clear', options)
      root.toggle = () => window.zE('webWidget', 'toggle')
      root.reset = () => window.zE('webWidget', 'reset')
      root.close = () => window.zE('webWidget', 'close')
      root.open = () => window.zE('webWidget', 'open')

      Object.defineProperty(root, 'zE', {
        get: function get() {
          return window.zE
        },
      })
      this.zendesk = root
      this.loadZendesk()
    },
  },
}
</script>

<style lang="scss">
.zendesk-button {
  position: fixed;
  bottom: $spacing-sm;
  right: $spacing-md;
  font-weight: 800;
  z-index: 1;
  height: $spacing-xxl;
  .btn-label {
    padding-right: 15px;
    @include mq($mq-sm) {
      display: none;
    }
  }
  .icon-zendesk {
    width: $spacing-md;
    height: $spacing-md;
    @include mqmin($mq-sm) {
      margin-right: -$spacing-xs;
    }
  }
}
</style>
