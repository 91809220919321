import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'

export function usePublicApi() {
  const { fetcher, backendUrl } = useCustomFetcher()
  const getInfos = async () => {
    const url = `${backendUrl}public/infos`
    return fetcher.get(url).then(response => response)
  }
  return {
    getInfos,
  }
}
