import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'

export function useAutocompleteApi() {
  const { fetcher, backendUrl } = useCustomFetcher()

  const getMarketplace = async (filters = []) => {
    const url = `${backendUrl}autocomplete/marketplace`
    const config = {
      params: {
        limit: 5,
      },
    }
    if (filters && filters.length > 0) {
      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params['search[query]'] = keywordsFilter.value
    }
    return fetcher.get(url, config).then(response => {
      return response.results
    })
  }

  const getStock = async (companyUid, filters = []) => {
    const url = `${backendUrl}companies/${companyUid}/autocomplete/stocks`
    const config = {
      params: {
        limit: 5,
      },
    }
    if (filters && filters.length > 0) {
      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params['search[query]'] = keywordsFilter.value
    }
    return fetcher.get(url, config).then(response => {
      return response.results
    })
  }

  return {
    getMarketplace,
    getStock,
  }
}
