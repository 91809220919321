import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'
import { useClubMapper } from '@/composables/Api/Mappers/useClubMapper'

export function useClubApi() {
  const { fetcher, backendUrl } = useCustomFetcher()
  const { currentClubMapping } = useClubMapper()
  const get = async (mapping = currentClubMapping) => {
    const url = `${backendUrl}company_groups/current`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return fetcher.get(url, config).then(response => {
      if (!response.success) {
        throw response.message
      }
      return response.company_group
    })
  }
  return { get }
}
