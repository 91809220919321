<template>
  <div :key="`${locale}-pro`" class="sp-app-container pro-layout">
    <ProHeader />
    <ClientOnly v-if="onStaging || onQA">
      <DevBanner :on-staging="onStaging" />
    </ClientOnly>
    <div :key="$route.fullPath">
      <slot />
    </div>
    <FooterSp :pro-page="true" />
    <ZendeskButton />
  </div>
</template>

<script setup>
import { useUtmStore } from '@/stores/utm'
import { useUserStore } from '@/stores/user'
import FooterSp from '@/components/Footer/Footer'
import ProHeader from '@/components/Header/ProHeader'
import DevBanner from '@/components/Banners/DevBanner'
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'

const { locale } = useCurrentLocale()

const config = useRuntimeConfig()

const onStaging = config.public.ENV_NAME === 'STAGING'
const onQA = config.public.ENV_NAME === 'QA'

const { dynamicMeta, defaultHead, defaultSeo } = useDefaultMetaData({
  dynamicMeta: { ogImage: 'https://d3brsr9pdomwt0.cloudfront.net/social/web_banner_pro.jpg' },
})
useHead(defaultHead())
useSeoMeta(defaultSeo())

onBeforeMount(() => {
  const { analytics, events } = useAnalytics()
  const userStore = useUserStore()
  analytics.sendEvent(events.LOGIN_STATUS, userStore)
})

onMounted(() => {
  const utmStore = useUtmStore()
  const utms = getUtmTags()
  utms.forEach(utm => {
    utmStore.add(utm)
  })
  nextTick(() => {
    if (!window.gtmLoaded) {
      window.gtmLoaded = true
      const gtm = useGtm()
      gtm.enable(true) // Enable GTM dynamically
    }
  })
})
const route = useRoute()
const getUtmTags = () => {
  const utms = []
  const queryParams = Object.keys(route.query)
  const utmParams = queryParams.filter(key => key.startsWith('utm_'))
  utmParams.forEach(param => {
    utms.push(`${param}:${route.query[param]}`)
  })
  return utms
}
</script>
