<template>
  <div v-if="stock && !fastAddToCart" class="add-to-cart-actions">
    <div>
      <QuantitySelector
        :stock="stock"
        class="u-p-b-sm"
        :element-test="elementTest + '-quantity'"
        @on-cart-quantity-change="onQuantityChange"
      />
      <span :data-test-id="stock.isBatch ? elementTest + '-quantity-seller-batch' : elementTest + '-quantity-seller'">{{
        stock.isBatch
          ? $t('stock.quantitySellerByBatch', {
              quantity: stock.batchSize,
              type: $t(`stock.quantityTypes.${stock.unit}`, stock.batchSize)?.toLowerCase(),
            })
          : $t('stock.quantitySeller', {
              quantity: stock.quantity,
              type: $t(`stock.quantityTypes.${stock.unit}`, stock.quantity),
            })
      }}</span>
    </div>

    <div class="add-to-cart-button u-p-t-md" :data-test-id="elementTest + '-add-to-cart'">
      <MainButton
        :label="isClub ? $t('actions.book') : $t('actions.addToCart')"
        :data-test-id="isClub ? elementTest + '-button-book' : elementTest + '-button-add-to-cart'"
        tag="button"
        :class-variant="[!isClub ? 'btn-green' : '', 'full-width', 'btn-bold', 'btn-hover-dark-blue']"
        @click="addToCart()"
      />
    </div>

    <div v-if="showStickyBtn" class="add-to-cart-button show-sm" :class="{ 'show-btn': showStickyBtn }">
      <div>
        <QuantitySelector
          :stock="stock"
          :product-stepper="false"
          class="u-p-b-sm"
          @on-cart-quantity-change="onQuantityChange"
        />
        <MainButton
          :label="isXxs ? (isClub ? $t('actions.book') : $t('actions.addToCart')) : ''"
          tag="button"
          icon-position="LEFT"
          :class-variant="[!isClub ? 'btn-green' : '', 'full-width', 'btn-bold', 'btn-hover-dark-blue']"
          @click="addToCart()"
        >
          <div v-if="isSm && !isXxs" class="icon-cart u-bg-white" />
        </MainButton>
      </div>
    </div>
  </div>
  <div v-else-if="stock && fastAddToCart" class="fast-cart" @click.prevent="addToCart()">
    <MainButton
      tag="button"
      :class-variant="[!isClub ? 'btn-green' : '', { added: addedFast }, { mobile: !isPc }, 'fast-cart-button']"
      :data-test-id="elementTest + '-button-fast-cart'"
    >
      <div class="icon-cart u-bg-white" :data-test-id="elementTest + '-icon-cart-fast-cart'" />
      <div class="icon-plus u-bg-white" :data-test-id="elementTest + '-icon-plus-fast-cart'" />
      <div v-if="addedFast" class="icon-check u-bg-primary" :data-test-id="elementTest + '-icon-check-fast-cart'" />
    </MainButton>
  </div>
</template>

<script setup>
import { useClubStore } from '@/stores/club'
import { useCartStore } from '@/stores/cart'
import { useDeviceSize } from '@/composables/useDeviceSize'
import { useEmitterStore } from '@/stores/emitter'

import MainButton from '@/components/Buttons/MainButton/MainButton'
import QuantitySelector from '@/components/Cart/CartQuantitySelector/CartQuantitySelector'

const props = defineProps({
  stock: { type: Object, default: null },
  fastAddToCart: { type: Boolean, default: false },
  elementTest: { type: String, default: '' },
  isUpsell: { type: Boolean, default: false },
})
const emit = defineEmits(['add-to-cart'])
const quantity = ref(1)
const showStickyBtn = ref(false)
const { isSm, isPc, isXxs } = useDeviceSize()

const isClub = computed(() => {
  const clubStore = useClubStore()
  return clubStore.isClub
})
const addedFast = computed(() => {
  const cartStore = useCartStore()
  return cartStore.getIsInCart(props.stock.uid)
})

onMounted(() => {
  if (!props.fastAddToCart) {
    window.addEventListener('scroll', checkShowStickBtn(), { passive: true })
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', checkShowStickBtn())
})
const checkShowStickBtn = () => {
  const elementTarget = document.getElementById('companyDeliveryDetails')
  if (elementTarget) {
    showStickyBtn.value =
      window.scrollY - elementTarget.offsetTop - document.querySelector('.menu-top').offsetHeight >
      elementTarget.offsetTop + elementTarget.offsetHeight
  }
}
const onQuantityChange = value => {
  quantity.value = value
}
const addToCart = () => {
  const emitter = useEmitterStore()
  emitter.emit('addToCartAction', {
    accepted: false,
    stock: props.stock,
    quantity: quantity.value,
    fastAddToCart: props.fastAddToCart,
    isUpsell: props.isUpsell,
  })
  emit('add-to-cart')
}
</script>

<style lang="scss">
.add-to-cart-actions {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-md;

  @include mq($mq-sm) {
    flex-direction: column;
  }

  .cart-quantity-selector {
    margin-right: 20px;
    flex: 1;

    @include mq($mq-sm) {
      margin-right: 0;
    }
  }
  li {
    list-style: disc;
    list-style-position: inside;
  }

  .add-to-cart-button.show-btn {
    @include mq($mq-md) {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      margin-left: -40px;
      padding: $spacing-md !important;
      background-color: rgba(white, 0.9);
    }

    & > div {
      display: flex;
      @include mq($mq-sm) {
        gap: $spacing-md;
      }
      @include mq($mq-xxs) {
        flex-direction: column;
        flex-grow: 1;
        gap: unset;
      }

      .cart-quantity-selector {
        @include mq($mq-sm) {
          padding-bottom: 0 !important;
        }
        @include mq($mq-xxs) {
          padding-bottom: $spacing-sm !important;
        }

        .number-stepper {
          display: flex;

          .btn-wrapper {
            display: flex;
            width: 100%;

            .stepper-value-wrapper {
              flex: auto;

              .sp-input-field {
                width: 100%;

                .sp-input {
                  max-width: unset;
                  text-align: center;
                  padding: 0 $spacing-sm;
                }
              }
            }
          }
        }
      }

      .btn.has-icon {
        padding: $spacing-sm;
      }

      .btn.has-icon {
        flex-grow: 0.5;
      }
    }

    @include mq($mq-sm) {
      margin-left: -20px;
    }
  }
}
.icon-problem-grapefruit {
  width: 30px;
  height: 30px;
  mask-image: url('@/assets/img/svg/icons/ico-problem.svg');
  margin-right: 10px;
  background-color: var(--grapefruit);
}

.fast-cart {
  display: inline-block;
  height: 35px;

  @include mq($mq-sm) {
    margin-top: $spacing-sm;
  }

  .fast-cart-button {
    border: 2px solid var(--primary);
    box-sizing: border-box;
    height: 35px;
    padding: 0 $spacing-xs !important;
    width: 56px;

    div {
      transform: scale(0.666);

      &:not(:first-of-type) {
        margin-left: -$spacing-sm;
      }
    }

    .icon-check {
      display: none;
    }

    &:hover {
      background-color: var(--primary) !important;

      &::before {
        opacity: 0;
      }
    }

    &:hover div {
      background-color: var(--night-blue) !important;
    }

    &.added {
      background-color: var(--white);

      div {
        background-color: var(--primary) !important;
      }
      .icon-plus {
        display: none;
      }
      .icon-check {
        display: block;
      }

      &:hover {
        background-color: var(--primary);

        div {
          background-color: var(--white) !important;
        }
        .icon-plus {
          display: block;
        }
        .icon-check {
          display: none;
        }
      }

      &.mobile {
        background-color: var(--white) !important;

        div {
          background-color: var(--primary) !important;
        }
        .icon-plus {
          display: none;
        }
        .icon-check {
          display: block;
        }
      }
    }
  }

  &.referencecell-home {
    position: absolute;
    bottom: $spacing-md;
    right: $spacing-md;
  }
  &.featured-home {
    position: absolute;
    right: $spacing-sm;
    bottom: $spacing-sm;
  }
}

.snackbar {
  top: $spacing-md;
  left: unset;
  right: $spacing-md;
  transform: none;
  justify-content: center;

  @include mq($mq-xs) {
    left: $spacing-md;
    right: $spacing-md;
  }

  span {
    display: inline-flex;
    align-items: center;
    padding-left: $spacing-md;
    padding-right: $spacing-md;

    .icon-check {
      transform: scale(0.7);
    }
  }
}
</style>
