export function useCartMapper() {
  const basicMapping = JSON.stringify({
    mapping: {
      cart: ['uid'],
    },
  })

  const cartMapping = JSON.stringify({
    mapping: {
      cart: [
        'uid',
        'delivery_address',
        'total_price',
        'total_delivery_price_vat',
        'total_items_price_vat',
        'total_items_price',
        'total_delivery_price',
        'total_discount_vat',
        'total_discount',
        'total_price_vat',
        'discount_price_vat',
        'vat',
        'coupons',
        'stripe_secret_intents',
        {
          stocks: [
            'uid',
            'quantity',
            'current_cart_quantity',
            'mkp_reserved_qty',
            'price',
            'selling_quantity',
            'total_price',
            'total_price_vat',
          ],
        },
        {
          orders: ['uid', 'accreditation'],
        },
        {
          merchants: [
            'order',
            'delivery',
            {
              stocks: [
                'current_cart_quantity',
                'mkp_reserved_qty',
                'price',
                'selling_quantity',
                'total_price',
                'total_price_vat',
                {
                  reference: [
                    'uid',
                    'slug',
                    'name',
                    'recommended_price',
                    'ref_co',
                    {
                      brand: ['name'],
                    },
                    {
                      images: ['url', 'url_thumbnail', 'url_medium', 'url_large'],
                    },
                  ],
                },
              ],
            },
            {
              company: ['name', 'slug'],
            },
          ],
        },
      ],
    },
  })

  const resumeMapping = JSON.stringify({
    mapping: {
      cart: [
        'uid',
        'delivery_address',
        'total_price',
        'total_delivery_price_vat',
        'total_items_price_vat',
        'total_items_price',
        'total_delivery_price',
        'total_discount_vat',
        'total_discount',
        'total_price_vat',
        'discount_price_vat',
        'vat',
        'coupons',
        'stripe_secret_intents',
        {
          stocks: [
            'uid',
            'quantity',
            'quantity_type',
            'current_cart_quantity',
            'mkp_reserved_qty',
            'price',
            'price_vat',
            'selling_quantity',
            'expiry_date',
            'expiry_date_status',
            'has_delivery',
            'location',
            {
              storage: [
                'uid',
                'name',
                'gps',
                'address',
                'zip_code',
                'city',
                'country',
                'kind',
                {
                  parent: ['uid', 'name', 'address', 'zip_code', 'city', 'country', 'kind'],
                },
              ],
            },
            {
              company: [
                'uid',
                'slug',
                'name',
                'label',
                'has_delivery',
                'has_pick_up',
                'delivery_methods',
                'delivery_fee',
                'delivery_free_of_charge',
                'delivery_free_of_charge_vat',
                'delivery_delay',
              ],
            },
            {
              reference: [
                'uid',
                'name',
                'slug',
                'recommended_price',
                'best_stock',
                'category',
                'description',
                'public_stocks_count',
                'ref_co',
                'ean',
                'manual_link',
                'weight',
                'unit_weight',
                'weight_unit',
                'has_delivery',
                'public_stocks_quantities',
                'accreditation',
                {
                  images: ['url', 'url_thumbnail', 'url_medium', 'url_large'],
                },
                {
                  category: ['uid', 'name', 'slug'],
                },
                {
                  brand: ['name', 'slug', 'logo_url'],
                },
              ],
            },
          ],
        },
        // {
        //   merchants: [
        //     'order',
        //     'delivery',
        //     {
        //       stocks: [
        //         'current_cart_quantity',
        //         'price',
        //         'selling_quantity',
        //         'total_price',
        //         'total_price_vat',
        //         {
        //           reference: [
        //             'uid',
        //             'slug',
        //             'name',
        //             'recommended_price',
        //           ],
        //         },
        //         {
        //           company: [
        //             'uid',
        //             'name',
        //             'label',
        //             'has_delivery',
        //             'has_pick_up',
        //             'delivery_methods',
        //             'delivery_fee',
        //             'delivery_free_of_charge',
        //             'delivery_free_of_charge_vat',
        //             'delivery_delay',
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
  })

  return {
    basicMapping,
    cartMapping,
    resumeMapping,
  }
}
