<template>
  <NuxtLinkLocale
    v-if="reference && reference.hasStock"
    :to="{
      name: 'product-slug',
      params: { slug: referenceInfo.slug },
      query: { s: stock?.uid, m: stock?.company?.uid },
    }"
    class="featured-products-card-item-wrapper"
    :data-test-id="testId + '-card-item-wrapper'"
  >
    <div class="featured-products-card-item" :data-test-id="testId + '-card-item'">
      <div class="featured-products-card-item-img" :data-test-id="testId + '-card-item-img'">
        <div class="product-img" :data-test-id="testId + '-product-img'">
          <ImageLazy :src="reference.mainImageUrl" :alt="reference.name" :background-image="true" width="285" />
          <div v-if="reference.hasBrand" class="product-brand" :data-test-id="testId + '-product-brand'">
            <ImageLazy
              v-if="reference.hasBrandLogo && brand"
              :src="brand.thumbnail"
              :alt="brand.name"
              :title="brand.name"
            />
            <span v-else-if="brand">{{ brand.name }}</span>
          </div>
        </div>
        <AddToCart
          v-if="fastAddToCart"
          ref="fastAddToCart"
          :stock="stock"
          :fast-add-to-cart="true"
          :is-upsell="isUpsell"
          :element-test="testId"
          class="featured-home"
        />
      </div>
      <div class="featured-products-card-item-info" :data-test-id="testId + '-card-item-info'">
        <div class="featured-products-card-item-info-name" :data-test-id="testId + '-card-item-info-name'">
          {{ reference.name }}
        </div>
        <div class="featured-products-card-item-info-price" :data-test-id="testId + '-card-item-info-price'">
          <div class="featured-products-card-item-info-price-val" :data-test-id="testId + '-card-item-info-price-val'">
            {{ localePrice(stock?.storePrice(showVAT)) }}
          </div>
          <div
            v-if="reference.price != null && catalogPriceEquivalent > 0 && stock.percentageOff() < 0"
            class="featured-products-card-item-info-price-before"
            :data-test-id="testId + '-card-item-info-price-before'"
          >
            {{ localePrice(catalogPriceEquivalent) }}
          </div>
        </div>
      </div>
    </div>
  </NuxtLinkLocale>
</template>

<script setup>
import AddToCart from '@/components/Cart/AddToCart/AddToCart'

const { localePrice, showVAT } = usePrice()

const props = defineProps({
  referenceInfo: { type: Object, default: () => null },
  fastAddToCart: { type: Boolean, default: false },
  testId: { type: String, default: '' },
  isUpsell: { type: Boolean, default: false },
})

const reference = computed(() => {
  if (props.referenceInfo) {
    return useReference(props.referenceInfo)
  }
  return null
})

const brand = computed(() => {
  return reference.value.hasBrand && reference.value.brand ? useBrand(reference.value.brand) : null
})

const stock = computed(() => {
  if (reference.value.hasStock) {
    return useStock({
      ...reference.value.bestStock,
      reference: reference.value,
      // If is upsell, we get company infos direclty from reference.company from endpoint upsell
      ...(props.isUpsell && { company: reference.value.company }),
    })
  }

  return null
})

const catalogPriceEquivalent = computed(() => {
  return stock.value?.catalogPriceEquivalent(showVAT.value)
})
</script>

<style lang="scss">
.featured-products-card-item-wrapper {
  text-decoration: none;
  .featured-products-card-item {
    width: 305px;
    height: 355px;
    padding: $spacing-sm;
    border: 1px solid var(--bg-grey);
    border-radius: $spacing-xs;

    &-img .product-img,
    &-img .product-img div:first-of-type {
      height: 285px;
      width: 285px;
    }

    &:hover {
      cursor: pointer;
      .featured-products-card-item-info-name {
        text-decoration: underline;
      }
    }

    &-img {
      position: relative;
      .product-img {
        margin: 0 0 $spacing-sm 0;
        position: relative;

        div:first-of-type {
          background-size: contain !important;
        }

        .product-brand {
          border-radius: 0 $spacing-sm 0 0;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 5px;
          background-color: var(--white);

          img {
            display: block;
            max-width: 65px;
            max-height: 50px;
            width: 65px;
            height: 50px;
            width: auto;
            height: auto;
          }

          span {
            color: var(--night-blue);
            text-transform: uppercase;
            font-size: pxToRem(14px);
            font-weight: 800;
          }
        }
      }
    }

    &-info {
      display: flex;
      gap: $spacing-sm;

      &-name {
        color: var(--dark-blue);
        @include line-clamp(2);
      }

      &-price {
        &-val {
          color: var(--dark-blue);
          font-weight: 800;
        }

        &-before {
          color: var(--steel-grey);
          font-size: pxToRem(12px);
          text-decoration: line-through;
        }
      }
    }
  }
}
.cart-upsell-layout {
  .featured-products-card-item {
    background: var(--white);
  }
  .featured-products-card-item-info {
    justify-content: space-between;
  }
}
</style>
