<template>
  <div class="navbar-left">
    <div v-if="user?.isAuthenticated" class="navbar-left-list" data-test-id="home-nav-left">
      <NuxtLinkLocale
        v-if="(user.isPro && user.isOwner && user.hasCompanyOptionsDashboard) || user.isAdmin"
        tag="div"
        data-test-id="home-dashboard-list-button"
        :to="'dashboard'"
      >
        <div class="navbar-left-list-item" :class="{ active: $route.name?.includes('dashboard') }">
          <div class="navbar-left-list-item-icon-container">
            <div class="navbar-left-list-item-icon icon-dashboard u-bg-steel-grey" />
          </div>
          <p>{{ $t('pages.dashboard') }}</p>
        </div>
      </NuxtLinkLocale>

      <NuxtLinkLocale :to="'index'" tag="div">
        <div
          class="navbar-left-list-item"
          :class="{ active: routesMkp.some(v => $route.name?.split('-')[0]?.includes(v)) }"
        >
          <div class="navbar-left-list-item-icon-container">
            <div class="navbar-left-list-item-icon icon-cart u-bg-steel-grey" />
          </div>
          <p>{{ $t('pages.marketplace') }}</p>
        </div>
      </NuxtLinkLocale>

      <client-only>
        <NuxtLinkLocale v-if="stockButton && tld === 'FR'" :to="'stock'" tag="div">
          <div
            class="navbar-left-list-item"
            data-test-id="home-stock-list-button"
            :class="{ active: $route.name?.includes('stock') }"
          >
            <div class="navbar-left-list-item-icon-container" data-test-id="home-stock-list-button-icon-container">
              <div
                class="navbar-left-list-item-icon icon-company u-bg-steel-grey"
                data-test-id="home-stock-list-button-icon"
              />
            </div>
            <p data-test-id="home-stock-list-button-text">{{ $t('pages.stock') }}</p>
          </div>
        </NuxtLinkLocale>
      </client-only>
      <template v-if="user?.clubs?.length">
        <template v-for="club in user.clubs" :key="club.uid">
          <a :href="getClubUrl(club)" tag="div">
            <div class="navbar-left-list-item">
              <div
                class="navbar-left-list-item-icon-container"
                :data-test-id="`home-club-${club?.name?.replace(/\s+/g, '-')?.toLowerCase()}`"
              >
                <div class="navbar-left-list-item-icon icon-club u-bg-steel-grey" />
              </div>
              <p>{{ $t('club.clubName', { name: club.name }) }}</p>
            </div>
          </a>
        </template>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useClubStore } from '@/stores/club'
import { useUserStore } from '@/stores/user'
const clubStore = useClubStore()

const routesMkp = ref(['index', 'product', 'category', 'brand', 'merchant'])

const user = computed(() => {
  const userStore = useUserStore()
  return userStore
})
const stockButton = computed(() => {
  return !clubStore.isClub && user.value.isPro && !user.value.isFreemium
})
const tld = computed(() => {
  const { tld } = useCurrentLocale()
  return tld
})

const getClubUrl = club => {
  if (import.meta.client) {
    return window.location.host.includes('www')
      ? `${window.location.protocol}//${window.location.host.replace('www', club.key)}`
      : `${window.location.protocol}//${club.key}.${window.location.host}`
  } else {
    return ''
  }
}
</script>

<style lang="scss">
.navbar-left {
  position: fixed;
  top: 85px;
  width: 100px;
  z-index: 2;

  @include mq($mq-sm) {
    display: none;
  }

  &-list {
    padding-top: $spacing-sm;

    a {
      text-decoration: none;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing-xs;
      margin-bottom: $spacing-lg;
      padding-left: $spacing-lg;
      width: 58px;
      text-align: center;

      p {
        color: var(--steel-grey) !important;
        font-weight: 900;
        font-size: pxToRem(9px) !important;
        width: 58px;
      }

      &-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
        height: 28px;
      }

      &.active,
      &:hover {
        cursor: pointer;

        .navbar-left-list-item-icon-container {
          background-color: var(--primary);
          border-radius: 15px;
        }

        .navbar-left-list-item-icon {
          background-color: var(--white) !important;
        }

        p {
          color: var(--primary) !important;
        }
      }
    }
  }
}
</style>
